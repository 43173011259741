import {
  DARK_FOLDER,
  DARK_NOTIFICATION,
  DARK_OVERVIEW,
  DARK_SETTINGS,
  WHITE_FOLDER,
  WHITE_NOTIFICATION,
  WHITE_OVERVIEW,
  WHITE_SETTINGS,
} from "../../assets";

const LaisonHeaderRouter = [
  {
    id: 1,
    title: "Overview",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_OVERVIEW} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_OVERVIEW} />
    ),
    route_to: "/laison-officer/overview",
  },
  {
    id: 2,
    title: "Folders",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_FOLDER} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_FOLDER} />
    ),
    route_to: "/laison-officer/case-folders",
  },
  {
    id: 3,
    title: "Notifications",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_NOTIFICATION} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_NOTIFICATION} />
    ),
    route_to: "/laison-officer/notifications",
  },
  {
    id: 4,
    title: "Settings",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_SETTINGS} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_SETTINGS} />
    ),
    route_to: "/laison-officer/settings",
  },
];
const LegalOfficerHeaderRouter = [
  {
    id: 1,
    title: "Overview",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_OVERVIEW} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_OVERVIEW} />
    ),
    route_to: "/legal-officer/overview",
  },
  {
    id: 2,
    title: "Folders",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_FOLDER} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_FOLDER} />
    ),
    route_to: "/legal-officer/case-folders",
  },
  {
    id: 3,
    title: "Notifications",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_NOTIFICATION} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_NOTIFICATION} />
    ),
    route_to: "/legal-officer/notifications",
  },
  {
    id: 4,
    title: "Settings",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_SETTINGS} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_SETTINGS} />
    ),
    route_to: "/legal-officer/settings",
  },
];
const UnitHeaderRouter = [
  {
    id: 1,
    title: "Overview",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_OVERVIEW} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_OVERVIEW} />
    ),
    route_to: "/unit-head/overview",
  },
  {
    id: 2,
    title: "Folders",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_FOLDER} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_FOLDER} />
    ),
    route_to: "/unit-head/case-folders",
  },
  {
    id: 3,
    title: "Notifications",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_NOTIFICATION} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_NOTIFICATION} />
    ),
    route_to: "/unit-head/notifications",
  },
  {
    id: 4,
    title: "Settings",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_SETTINGS} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_SETTINGS} />
    ),
    route_to: "/unit-head/settings",
  },
];
const DirectorsHeaderRouter = [
  {
    id: 1,
    title: "Overview",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_OVERVIEW} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_OVERVIEW} />
    ),
    route_to: "/director/overview",
  },
  {
    id: 2,
    title: "Folders",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_FOLDER} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_FOLDER} />
    ),
    route_to: "/director/case-folders",
  },
  {
    id: 3,
    title: "Notifications",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_NOTIFICATION} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_NOTIFICATION} />
    ),
    route_to: "/director/notifications",
  },
  {
    id: 4,
    title: "Settings",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_SETTINGS} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_SETTINGS} />
    ),
    route_to: "/director/settings",
  },
];
const AttorneyHeaderRouter = [
  {
    id: 1,
    title: "Overview",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_OVERVIEW} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_OVERVIEW} />
    ),
    route_to: "/attorney-general/overview",
  },
  {
    id: 2,
    title: "Folders",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_FOLDER} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_FOLDER} />
    ),
    route_to: "/attorney-general/case-folders",
  },
  {
    id: 3,
    title: "Notifications",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_NOTIFICATION} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_NOTIFICATION} />
    ),
    route_to: "/attorney-general/notifications",
  },
  {
    id: 4,
    title: "Settings",
    icon_active: (
      <img alt='' className='w-[24px] h-[24px]' src={WHITE_SETTINGS} />
    ),
    icon_inactive: (
      <img alt='' className='w-[24px] h-[24px]' src={DARK_SETTINGS} />
    ),
    route_to: "/attorney-general/settings",
  },
];

export {
  AttorneyHeaderRouter,
  DirectorsHeaderRouter,
  LaisonHeaderRouter,
  LegalOfficerHeaderRouter,
  UnitHeaderRouter,
};

export interface HeaderDataTypes {
  id: number;
  title: string;
  icon_active: any;
  icon_inactive: any;
  route_to: string;
}
