import React from "react";
import { Zoom } from "react-awesome-reveal";

import { SUIT_CHECK } from "../assets";
import Button from "./button";

interface SuccessProps {
  title: string;
  onClick: () => void;
  img: string;
}

const Successful: React.FC<SuccessProps> = ({ title, onClick, img }) => (
  <div>
    <Zoom>
      <div className='flex mx-auto justify-center'>
        <img alt='' src={img || SUIT_CHECK} srcSet='' />
      </div>

      <div className='flex flex-col pt-3 md:pt-4 px-8 md:px-8 lg:py-4'>
        <div className='flex justify-between items-center flex-wrap mb-6'>
          <div className='flex flex-col pt-2  w-full'>
            <p className='text-center text-[#000] text-[20px] leading-[26px]'>
              {title}
            </p>
          </div>
        </div>
        <div className=''>
          <Button
            bgColor='bg-brand_blue'
            className=' mb-2  mt-4 w-full py-4 rounded-2xl'
            onClick={onClick}
            textColor='text-white'
            title='Close'
            type='button'
          />
        </div>
      </div>
    </Zoom>
  </div>
);

export default Successful;
