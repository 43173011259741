export default {
  notification: (id: string) => `notifications?id=${id}`,
  allJudges: () => `users/judges`,
  allDpps: (parameter: any) => `users/dpps?role=${parameter.role}`,
  allUsers: () => `user-entity/users`,
  addUsers: () => `user-entity/add-user`,
  addCourtStaff: () => `/court-staffs`,
  updateStaffRole: (id: string) => `/court-staffs/${id}/update`,
  deleteCourtStaff: (id: string) => `/court-staffs/${id}`,
  getCourtStaffs: () => `/court-staffs`,
  getAllcases: (
    page: number,
    status: string,
    condition: string,
    my_judicial_division: number,
  ) =>
    `case-folders?page=${page}&status=${status ?? ""}&condition=${condition ?? ""}&my_judicial_division=${my_judicial_division}`,
  getCaseHolders: (case_folder_id: string) =>
    `case-folders/${case_folder_id}/case-holders`,
  showJoinCaseRequest: (case_join_request_id: string) =>
    `case-folders/${case_join_request_id}/show-from-join-request`,
  showCaseFolder: (case_folder_id: string) =>
    `case-folders/${case_folder_id}/show`,
  updateUsers: (id: any) => `user-entity/${id}/update`,
  deleteUsers: (id: any) => `user-entity/${id}`,
  AddSignature: `signatures`,
  deleteSignature: (id: any) => `signatures/${id}`,
  resolveQuery: (case_folder_id: any) =>
    `case-folders/${case_folder_id}/resolve-case-queries`,
  getPayments: (parameter: any) =>
    `payments?status=${parameter.status}&page=${parameter.page}`,
  caseQueries: `case-queries`,
  createDocuements: "case-documents",
  subjectMatters: "subject-matters",
  judicialDivisions: "judicial-divisions",
  searchCaseBySuitNo: (parameter: any) =>
    `case-folders/suit-number?suit_number=${parameter}`,
};
