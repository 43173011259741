export const computedStatusStyle = (item: any) => {
  if (item.case_status?.status === "MARKED_FOR_SERVICE") {
    return "text-[#000] bg-[#FFED9C] rounded-full px-4 py-2  ";
  }
  if (item.case_status?.status === "SUBMITTED") {
    return "text-[#ffffff] bg-brand_green px-4 py-2  text-center text-xs   rounded-full  ";
  }

  if (item.case_status?.status === "AWAITING_SERVICE") {
    return "text-[#000] bg-[#DB791E] px-4 py-2  text-center text-xs   rounded-full   ";
  }
  if (item.case_status?.status === "AWAITING_SUBMISSION") {
    return "text-[#ffffff] bg-[#DB791E] rounded-full px-4 py-2    text-center text-xs  ";
  }
  if (item.case_status?.status === "PAYMENT_ADVICED") {
    return "text-[#ffffff] bg-[#000] rounded-full px-4 py-2  text-center text-xs  ";
  }
  if (item.case_status?.status === "READY_FOR_ASSIGNMENT") {
    return "text-brand_green bg-[#BFF2D8] rounded-full px-4 py-2    text-center text-xs  ";
  }
  if (item.case_status?.status === "PICKED_UP") {
    return "text-[#ffffff] bg-[#478EFD] rounded-full px-4 py-2    text-center text-xs  ";
  }
  if (item.case_status?.status === "SERVED") {
    return "text-[#ffffff] bg-[#AE5FE8] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "SERVICE_COMPLETED") {
    return "text-[#000000] bg-[#FED831] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "SERVICE_COMPLETED") {
    return "text-[#000000] bg-[#FED831] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "REASSIGNED") {
    return "text-[#ffffff] bg-[#979797] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "RECALL_REQUEST") {
    return "text-[#000000] bg-[#FFED9C] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "RECUSAL_REQUEST") {
    return "text-[#000000] bg-[#FBBCBD] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "DPP_REQUEST") {
    return "text-[#000000] bg-[#B0CFFF] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "ASSIGNED_TO_COURT") {
    return "text-[#000000] bg-[#FED831] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "RECALLED") {
    return "text-[#000000] bg-[#FFED9C] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "RECUSED") {
    return "text-[#000000] bg-[#B0CFFF] rounded-full px-4 py-2  text-center text-xs ";
  }
  if (item.case_status?.status === "RECALL_DECLINED") {
    return "text-[#000000] bg-[#B0CFFF] rounded-full px-4 py-2  text-center text-xs ";
  }

  return "";
};
export const computedDppStatusStyle = (item: any) => {
  if (item?.dpp_status === "REFERRED_TO_DPP") {
    return "text-[#00A573]  px-4 py-2  ";
  }
  if (item?.dpp_status === "REQUEST_PENDING") {
    return "text-[#FF9500]  ";
  }

  if (item?.dpp_status === "SENT_TO_COURT") {
    return "text-[#219653]";
  }
  if (item?.dpp_status === "REQUEST_IN_PROGRESS") {
    return "text-[#5856D6]  ";
  }

  return "";
};
