/* eslint-disable react/no-array-index-key */
import React from "react";
import { Route, Routes } from "react-router-dom";

import { AttorneyGenralRoute } from "../dataroutes/attorneyroute";
import { DirectorsRoute } from "../dataroutes/directorsroute";
import { LaisonOfficerRoute } from "../dataroutes/laisonofficerroute";
import { LegalOfficersRoute } from "../dataroutes/legalofficer";
import { UnitHeadsRoute } from "../dataroutes/unitheadroute";
import { useAppSelector } from "../hooks";
import { DashboardLayout } from "../layouts/dashboard";
import { selectRole } from "../states/slices/authReducer";
import { UserType } from "../utils/Enums";

// main routing modaules

function MainRoutes() {
  const role = useAppSelector(selectRole);

  return (
    <div>
      <DashboardLayout>
        <Routes>
          {role === UserType.LIAISON_OFFICER &&
            LaisonOfficerRoute.map((item, index) => (
              <Route
                element={<item.component />}
                key={index}
                path={item.path}
              />
            ))}
          {role === UserType.LEGAL_OFFICER &&
            LegalOfficersRoute.map((item, index) => (
              <Route
                element={<item.component />}
                key={index}
                path={item.path}
              />
            ))}
          {role === UserType.UNIT_HEAD &&
            UnitHeadsRoute.map((item, index) => (
              <Route
                element={<item.component />}
                key={index}
                path={item.path}
              />
            ))}
          {role === UserType.DIRECTOR &&
            DirectorsRoute.map((item, index) => (
              <Route
                element={<item.component />}
                key={index}
                path={item.path}
              />
            ))}
          {role === UserType.ATTORNEY_GENERAL &&
            AttorneyGenralRoute.map((item, index) => (
              <Route
                element={<item.component />}
                key={index}
                path={item.path}
              />
            ))}
        </Routes>
      </DashboardLayout>
    </div>
  );
}

export default MainRoutes;
