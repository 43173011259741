import React from "react";
import { Link, useLocation } from "react-router-dom";

interface BreadcrumbProps {
  routes: { path: string; breadcrumbName: string }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ routes }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter(Boolean); // Get path segments

  return (
    <nav aria-label='breadcrumb'>
      <ol className='breadcrumb flex items-center space-x-2'>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`; // Create the path to match
          const isLast = index === pathnames.length - 1;
          const route = routes.find((r) => r.path === to); // Find matching route

          return isLast ? (
            <li
              className='breadcrumb-item active text-[14px] leading-[20px] font-[600]'
              key={to}
            >
              {route?.breadcrumbName ||
                value.charAt(0).toUpperCase() + value.slice(1)}
            </li>
          ) : (
            <React.Fragment key={to}>
              <li className='breadcrumb-item text-[#98A2B3] font-[400] text-[14px] leading-[20px]'>
                <Link to={to}>
                  {route?.breadcrumbName ||
                    value.charAt(0).toUpperCase() + value.slice(1)}
                </Link>
              </li>
              <span className='mx-2 text-[#667085]'>{">"}</span>
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
