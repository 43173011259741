export default {
  dashboard: "liaison-officer/dashboard",

  addDocument: (case_folder_id: string) =>
    `liaison-officer/case-folders/${case_folder_id}/add-document`,
  sendToCourt: (case_folder_id: string | null) =>
    `liaison-officer/case-folders/${case_folder_id}/send-to-court`,
  returnCase: (case_folder_id: string) =>
    `liaison-officer/case-folders/${case_folder_id}/return`,
  submitCase: (case_folder_id: string) =>
    `liaison-officer/case-folders/${case_folder_id}/submit`,
};
