export default {
  dashboard: "legal-officer/dashboard",

  submitCase: (case_folder_id: string) =>
    `legal-officer/case-folders/${case_folder_id}/submit`,
  return: (case_folder_id: string) =>
    `legal-officer/case-folders/${case_folder_id}/return`,

  addDocument: (case_folder_id: string) =>
    `legal-officer/case-folders/${case_folder_id}/add-document`,
};
