/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-empty-function */

import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ShowCaseFolder } from "../../../services/shared/service";
import {
  setToggle,
  setToggle_2,
  setToggle_3,
  updateCase,
  updateCaseDetails,
} from "../../../states/slices/partyInfo.slice";
import { computedDppStatusStyle } from "../../../utils/helperFunction";
import DropDownOptions from "../../dropdownBtn/DropDownOptions";

export const CASE_FOLDER_COLUMN = [
  {
    Header: "SN",
    Cell: ({ row }: { row: any }) => (
      <span className=' text-[#667085] text-[14px] leading-[18px]'>
        {row.index + 1}
      </span>
    ),
  },
  {
    Header: "Date Received",

    Cell: ({ row }: { row: any }) => (
      <span className='text-[#667085] text-[14px] leading-[18px]'>
        {moment(row?.original?.case_status?.created_at).format("Do MMM YY")}
      </span>
    ),
  },
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ row }: { row: any }) => (
      <span className='text-[#667085] text-[14px] leading-[18px]'>
        {row?.original?.title}
      </span>
    ),
  },

  {
    Header: "Suit number",
    accessor: "suit_number",
    Cell: ({ row }: { row: any }) => (
      <span className='text-[#667085] text-[14px] leading-[18px]'>
        {row?.original?.suit_number}
      </span>
    ),
  },

  {
    Header: "Query",
    // accessor: "created_by",
    Cell: ({ row }: { row: any }) => (
      <span className='font-normal text-sm text-[#667085]'>
        {row.original?.case_query?.status === "QUERIED" ? (
          <span className='text-[#FA4E4E]'>Queried</span>
        ) : (
          <span>N/A</span>
        )}
      </span>
    ),
  },
  {
    Header: "Stage",
    // accessor: "created_by",
    Cell: ({ row }: { row: any }) => (
      <span className={`  ${computedDppStatusStyle(row.original)}`}>
        {row?.original?.dpp_status}
      </span>
    ),
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const navigate = useNavigate();
      const dispatch = useDispatch();

      const handleViewDetails = async () => {
        const caseFolderId = row.original.id;
        dispatch(setToggle(true));
        dispatch(updateCase(row.original));

        // Fetch and dispatch the case details
        const caseDetails = await ShowCaseFolder(caseFolderId, dispatch);
        dispatch(updateCaseDetails(caseDetails));
      };

      return (
        <DropDownOptions
          actionTitle='Open Folder'
          actionTitle2='Query'
          actionTitle3='Send Case Folder'
          details
          handleActionTitle2={() => {
            dispatch(setToggle_2(true));
            dispatch(updateCase(row.original));
          }}
          handleActionTitle3={() => {
            dispatch(setToggle_3(true));
            dispatch(updateCase(row.original));
          }}
          handleDetails={handleViewDetails}
          handleView={() => {
            dispatch(updateCase(row.original));
            navigate(
              `documents?id=${row.original.id}&name=${row.original?.title}`,
              {
                state: { data: row.original },
              },
            );
          }}
          isNotDelete
        />
      );
    },
  },
];
