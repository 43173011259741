import CaseFolder from "../features/directors/casefolder";
import AddDocument from "../features/directors/casefolder/addDocument";
import Documents from "../features/directors/casefolder/documents";
import Notification from "../features/directors/notification";
import Overview from "../features/directors/overview";
import Settings from "../features/directors/settings";

export const DirectorsRoute = [
  {
    id: 1,
    title: "Overview",
    component: Overview,
    path: "/director/overview",
  },
  {
    id: 2,
    title: "Overview",
    component: CaseFolder,
    path: "/director/case-folders",
  },
  {
    id: 2,
    title: "Documents",
    component: Documents,
    path: "/director/case-folders/documents",
  },
  {
    id: "10",
    title: "documents",
    component: AddDocument,
    path: "/director/case-folders/documents/upload-document",
  },
  {
    id: 3,
    title: "Notifications",
    component: Notification,
    path: "/director/notifications",
  },
  {
    id: 4,
    title: "Settings",
    component: Settings,
    path: "/director/settings",
  },
];
