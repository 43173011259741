/* eslint-disable react/jsx-no-useless-fragment */

import React, { useMemo } from "react";
import { Zoom } from "react-reveal";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

interface TableTypes {
  isNotPaginanated?: any;
  COLUMNS: any;
  DATA: any;
  page?: any;
  setPage?: any;
  className: any;
  selected?: any;
  onRowClick?: (rowData: any) => void; // Add prop for row click handler
}

export const TableComponent = ({
  COLUMNS,
  DATA,
  setPage,
  isNotPaginanated,
  page,
  className,
  selected,
  onRowClick, // Destructure onRowClick prop
}: TableTypes) => {
  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => DATA, [DATA]);

  const tableHooks = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const { getTableProps, getTableBodyProps, headerGroups, state, prepareRow } =
    tableHooks;

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    setPage(page + 1);
  };
  return (
    <Zoom>
      <div className='w-full overflow-scroll bg-transparent h-[60vh]'>
        <div className={`w-full  text-sm  ${className}`}>
          <table className='w-full text-sm text-left' {...getTableProps()}>
            <thead className='text-[#667085] text-[12px] leading-[18px] font-[500] bg-[#F9FAFB] '>
              {headerGroups?.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()} className=''>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      // className=' whitespace-nowrap text-left pl-6 pr-3 py-4  text-sm font-semibold'
                      className='py-3 px-6'
                      scope='col'
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className='px-8'>
              {tableHooks?.page?.map((row: any) => {
                prepareRow(row);

                return (
                  <tr
                    className={`*:bg-white border-b ${selected === row.original && "bg-blue-100 font-bold"}`}
                    // className={`hover:bg-blue-50 border-b border-b-gray-300 ${selected === row.original && "bg-blue-100 font-bold"}`}
                    {...row.getRowProps()}
                    onClick={() => onRowClick && onRowClick(row.original)} // Attach onClick handler to the row
                    style={{ cursor: "pointer" }} // Change cursor to pointer on hover
                  >
                    {row.cells.map((cell: any) => (
                      <td
                        // className='pl-6 pr-3 py-4 border-0  text-xs'
                        className='py-4 px-6'
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {isNotPaginanated ? (
          <></>
        ) : (
          <div className='flex justify-end items-center w-full px-2 lg:px-4 py-2'>
            <div className='flex justify-center items-center text-xs rounded-sm py-1  w-full '>
              {page > 1 && (
                <button
                  className=' cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand'
                  onClick={() => handlePrevious()}
                  type='button'
                >
                  Previous
                </button>
              )}

              <span className='mx-4 text-xs'>
                Page <strong>{page}</strong>{" "}
              </span>
              {data?.length > 14 && (
                <button
                  className='cursor-pointer px-4 py-2 w-40 border-2 border-brand rounded-lg text-brand'
                  onClick={() => handleNext()}
                  type='button'
                >
                  Next
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </Zoom>
  );
};
