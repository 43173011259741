/* eslint-disable unicorn/prevent-abbreviations */
import toast from "react-hot-toast";

import { API } from "../../config/index";
import { setGlobalLoading } from "../../states/slices/authReducer";
import {
  toggleState_3,
  toggleState_4,
} from "../../states/slices/partyInfo.slice";
import { UserType } from "../../utils/Enums";
import path from "./path";
import { AnalyticsTypes } from "./types";

// export const SendToCOurtApi = async (data: string, dispatch: any) => {
//   dispatch(setGlobalLoading(true));

//   try {
//     const response = await API.post(path.sendToCourt(data));
//     if (response) {
//       dispatch(setGlobalLoading(false));
//       dispatch(toggleState_2(false));
//       toast.success(response?.data?.message);
//     }
//   } catch (error: any) {
//     dispatch(setGlobalLoading(false));
//     toast.error(error?.response?.data?.message, {
//       duration: 10_000,
//     });
//   }
// };

export const SendToCourtApi = async (id: any) => {
  const response = await API.post(path.sendToCourt(id));
  return response?.data;
};

export const SendCaseApi = async (
  data: string,
  dispatch: any,
  user_id: any,
  role: any,
  setSuccessful: (value: boolean) => void,
) => {
  dispatch(setGlobalLoading(true));

  try {
    const response = await API.post(path.submitCase(data), {
      role,
      user_id,
    });
    if (response) {
      toast.success(response?.data?.message);
      dispatch(setGlobalLoading(false));
      dispatch(toggleState_3(false));
      setSuccessful(true);
    }
  } catch (error: any) {
    dispatch(setGlobalLoading(false));
    toast.error(error?.response?.data.message);
  }
};
export const ReturnCaseApi = async (data: string, dispatch: any) => {
  dispatch(setGlobalLoading(true));

  try {
    const response = await API.post(path.returnCase(data), {
      role: UserType.ATTORNEY_GENERAL,
    });
    if (response) {
      dispatch(setGlobalLoading(false));
      dispatch(toggleState_4(false));
      toast.success(response?.data?.message);
    }
  } catch (error: any) {
    dispatch(setGlobalLoading(false));
    toast.error(error?.response?.data?.message, {
      duration: 10_000,
    });
  }
};
export const getDashboardAnalytics = async () => {
  const response: AnalyticsTypes = await API.get(path.dashboard);
  return response?.data?.data;
};

export const addDocumentCaseApi = async (payload: any, id: string | any) => {
  const response = await API.post(path.addDocument(id), payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response?.data;
};
