/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { BsSearch } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import {
  ALERT,
  DPP_BACK,
  EMPTY,
  INFO,
  IS_SUCCESSFUL,
  UPLOAD_FILE_PIN,
} from "../../../assets";
import Button from "../../../components/button";
import DropDownOptions from "../../../components/dropdownBtn/DropDownOptions";
import Successful from "../../../components/Successful";
import Typography from "../../../components/Typography";
import Modal from "../../../components/UI/modal";
import { useAppSelector, useMediaQuery } from "../../../hooks";
import { CaseDocument, CaseFolder } from "../../../services/Laywer/types";
import { SendToCourtApi } from "../../../services/liaisonofficer/service";
import { ShowCaseFolder } from "../../../services/shared/service";
import {
  getSelectedCase,
  setToggle_2,
  toggleState_2,
} from "../../../states/slices/partyInfo.slice";
import { HandleOpenDocument } from "../../../utils";

function Documents() {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  const [open, setOpen] = useState(false);

  const [fileUrl, setFileUrl] = useState<string>("");
  const [search, setSearch] = useState("");

  const [data, setData] = useState<any>();

  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");
  const [searchParams] = useSearchParams();
  const idParams = searchParams.get("id") || "";
  const name = searchParams.get("name");
  const toggle2 = useSelector(toggleState_2);
  const dispatch = useDispatch();
  const [documentDetailsModal, setDocumentDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [concludeModal, setConcludeModal] = useState(false);
  const [concludeCompleteModal, setConcludeCompleteModal] = useState(false);
  const Case: CaseFolder = useAppSelector(getSelectedCase);

  const { data: showCaseDetails } = useQuery(
    ["showCaseDetails", idParams],
    () => ShowCaseFolder(idParams, dispatch),
  );

  // eslint-disable-next-line no-console
  console.log(idParams);
  const sendToCourtMutation = useMutation(SendToCourtApi);

  const handleSendToCourt = async () => {
    setLoading(true);

    try {
      const response = await sendToCourtMutation.mutateAsync(idParams);
      if (response) {
        setLoading(false);
        toast.success(response.message, {
          duration: 10_000,
        });
        setConcludeCompleteModal(true);
        setConcludeModal(false);
      }
    } catch (error: any) {
      toast.error(error.response.message, {
        duration: 10_000,
      });
      setLoading(false);
    }
  };

  const stepsDescriptions = [
    {
      id: 0,
      desc: "You are about to send this back to the court. Ensure the legal opinion is uploaded.",
    },
    {
      id: 1,
      desc: "Are you sure you want to complete this action?",
    },
  ];
  const handleNext = () => {
    if (step < stepsDescriptions.length - 1) {
      setStep(step + 1);
    }
  };
  return (
    <div className=''>
      <div className='breadcrumb border-b border-[#EAECF0] py-4 flex items-center gap-2 bg-[#E0E0E0] px-[60px]'>
        <button className='' onClick={handleBack} type='button'>
          {/* <ArrowLeftIcon className='text-black w-3 ' /> */}
          <img alt='' src={DPP_BACK} srcSet='' />
        </button>

        <div className='flex items-center justify-between py-6'>
          <Typography textStyle='text-[24px] leading-[31px] font-[400] text-[#222222]'>
            <>{name} </>
          </Typography>
        </div>
      </div>

      <div className='px-[60px] py-[30px] '>
        <div className='w-full bg-white  pb-20 lg:p-8 shadow rounded-[5px]'>
          <div className=' lg:flex justify-between items-center w-full  mb-6 lg:mb-0'>
            <div className='flex items-center border border-[#D9D9D9] py-[8px] px-[15px] rounded-[8px] '>
              <input
                className='bg-[transparent]  border-0 focus:outline-none focus:ring-0 w-[100%] placeholder:text-md placeholder:text-[#98A2B3]  '
                placeholder='Search'
                type='text'
                value={search}
                // onChange={searchNotificatio}
              />

              <BsSearch />
            </div>

            <div className=' flex items-center gap-2'>
              <button
                className='flex justify-center items-center gap-2 py-3 px-3 w-44  text-white bg-[#23A863] !text-sm rounded-[4px]'
                onClick={() => dispatch(setToggle_2(true))}
                type='button'
              >
                View Request note
              </button>
              <button
                className='flex justify-center items-center gap-2 py-3 px-3 w-44  text-white bg-[#23A863]  !text-sm rounded-[4px]'
                onClick={() =>
                  navigate(`upload-document?id=${idParams}&name=${name}`)
                }
                type='button'
              >
                Upload a document
              </button>
            </div>
          </div>

          <div className='mt-8 mb-[120px]'>
            <Zoom>
              <div className='mt-4 lg:mt-8 flex justify-start gap-2 lg:gap-[5%] items-end flex-wrap w-full'>
                {showCaseDetails && showCaseDetails?.case_documents ? (
                  showCaseDetails?.case_documents?.map(
                    (item: CaseDocument, index) => (
                      <div className='bg-[#ECEEF5]  relative mb-8 flex flex-col  pt-3 font-medium text-black rounded-[18px]  border-[2px]  border-[#ECEEF5] w-[46%] md:w-1/3 lg:w-[30%]'>
                        <img
                          alt=''
                          className='px-[34px]  self-center pt-[24px] w-full aspect-[1.2]'
                          loading='lazy'
                          srcSet='https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9f3639dc2a1a37cb286507b7dca7225201fcd38df2576cece62e4cb0227d1247?apiKey=bb278f95022541509fa4b766a9320e21&'
                        />

                        <div className='bg-white rounded-b-[18px] border-t-[2px] p-4 border-[#ECEEF5] py-6 flex items-center h-full  '>
                          <div className='flex justify-between text-base w-full flex-col gap-2'>
                            {isMobileView && isTabletView ? (
                              <div className='w-[70%] text-xs self-start overflow-ellipsis whitespace-nowrap font-semibold'>
                                {item?.title?.length > 9
                                  ? `${item?.title?.slice(0, 9)} ..`
                                  : item?.title}
                              </div>
                            ) : (
                              <div className='w-[85%] text-[16px] leading-[26px] self-start mt-2.5 overflow-ellipsis whitespace-nowrap font-semibold'>
                                {item?.title?.length > 19
                                  ? `${item?.title.slice(0, 20)} ..`
                                  : item?.title}
                              </div>
                            )}
                            <div className='text-[10px] lg:text-xs '>
                              {moment(item?.created_at).format("MMM Do YY")}
                            </div>
                          </div>
                          <div className='w-[30%] lg:w-[15%]'>
                            <DropDownOptions
                              actionTitle='View'
                              actionTitle2='Details'
                              handleActionTitle2={() => {
                                setData(item);
                                setDocumentDetailsModal(true);
                              }}
                              handleView={() => {
                                setOpen(true);
                                setFileUrl(item.file_path);
                              }}
                              // isFee
                              isNotDelete
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  )
                ) : (
                  <div className='w-full flex justify-center items-center '>
                    <div className='flex flex-col justify-center items-center h-52'>
                      <img alt='' className='h-32' src={EMPTY} />
                      <p className='text-center text-sm mt-4'>
                        There was no document attacxhed to this case
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Zoom>
          </div>

          <div className='footer py-2 bg-white  border-t border-[#D1D1D1]  w-full '>
            <div className='flex items-center py-4 gap-2 px-6'>
              <button
                className=' bg-[#EB5757] text-white mb-2 ml-auto w-[250px] py-4 px-6 rounded-md'
                onClick={() => setConcludeModal(true)}
                type='button'
              >
                Send to court
              </button>

              <div>
                <Tooltip id='view-tooltip' />
                <a
                  data-tooltip-content='This action will conclude this request and send the folder back to the court/judge. Ensure that you have uploaded the legal opinion.'
                  data-tooltip-id='view-tooltip'
                  data-tooltip-place='right-start'
                >
                  <img alt='' src={INFO} srcSet='' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {HandleOpenDocument(open, setOpen, setFileUrl, fileUrl)}
      <Modal
        close={() => {
          setDocumentDetailsModal(false);
        }}
        maxWidth='xs'
        open={documentDetailsModal}
      >
        <Zoom>
          <div className='px-12'>
            <div className='self-center text-[24px] leading-[31px] font-[600] text-center'>
              Document details
            </div>

            <div className='flex items-center justify-between '>
              <h1 className='font-[400] text-[18px] leading-[38px]'>
                Document title:
              </h1>
              <h1 className='  font-[400] text-[18px] leading-[38px]'>
                {data?.title}
              </h1>
            </div>

            <div className='flex items-center justify-between '>
              <h1 className='font-[400] text-[18px] leading-[38px]'>Owner:</h1>
              <h1 className='  font-[400] text-[18px] leading-[38px]'>
                {data?.title}
              </h1>
            </div>

            <div className='flex items-center justify-between '>
              <h1 className='font-[400] text-[18px] leading-[38px]'>
                Counsel:
              </h1>
              <h1 className='  font-[400] text-[18px] leading-[38px]'>
                {data?.title}
              </h1>
            </div>

            <div className='flex items-center justify-between '>
              <h1 className='font-[400] text-[18px] leading-[38px]'>
                Date Created:
              </h1>
              <h1 className='  font-[400] text-[18px] leading-[38px]'>
                {data?.title}
              </h1>
            </div>

            <div className='flex items-center justify-between '>
              <h1 className='font-[400] text-[18px] leading-[38px]'>
                Date Filed:
              </h1>
              <h1 className='  font-[400] text-[18px] leading-[38px]'>
                {data?.title}
              </h1>
            </div>
            <hr className='my-6' />

            <Button
              bgColor='bg-brand_blue'
              className=' mb-2  mt-4 w-full py-4 rounded-2xl'
              onClick={() => setDocumentDetailsModal(false)}
              textColor='text-white'
              title='Close'
              type='button'
            />
          </div>
        </Zoom>
      </Modal>

      {/* Modal for sending case file to court */}
      <Modal
        close={() => {
          setConcludeModal(false);
        }}
        maxWidth='xs'
        open={concludeModal}
      >
        <Zoom>
          <div className='flex flex-col pt-3 md:pt-4 px-8 md:px-8 lg:py-4'>
            <div className='flex justify-between items-center flex-wrap mb-6'>
              <div className='flex flex-col pt-2  w-full'>
                <img
                  alt=''
                  className='w-[56px] mx-auto pb-4'
                  src={ALERT}
                  srcSet=''
                />
                <div className='text-center text-[#474747] text-[20px] font-[600]'>
                  {stepsDescriptions[step]?.desc}
                </div>
              </div>
            </div>
            <div className='flex justify-between gap-2 items-center w-full'>
              <Button
                bgColor='bg-[#E0E0E0]'
                className=' mb-2  mt-4 w-full py-4 rounded-2xl'
                onClick={() => {
                  setConcludeModal(false);
                  setStep(0);
                }}
                textColor='text-black'
                title='Cancel'
                type='button'
              />
              <Button
                bgColor='bg-brand_blue'
                className='mb-2 mt-4 w-full py-4 rounded-2xl'
                isLoading={loading}
                onClick={
                  step < stepsDescriptions.length - 1
                    ? handleNext
                    : handleSendToCourt
                }
                textColor='text-white'
                title={step < stepsDescriptions.length - 1 ? "Continue" : "Yes"}
                type='button'
              />
            </div>
          </div>
        </Zoom>
      </Modal>

      <Modal
        close={() => setConcludeCompleteModal(false)}
        maxWidth='xs'
        open={concludeCompleteModal}
      >
        <Successful
          img={IS_SUCCESSFUL}
          onClick={() => {
            setConcludeCompleteModal(false);
          }}
          title=' Case folder sent successful'
        />
      </Modal>

      {/* Modal for View Request Note */}
      <Modal
        close={() => {
          dispatch(setToggle_2(false));
        }}
        maxWidth='xs'
        open={toggle2}
      >
        <Zoom>
          <div className='flex flex-col pt-3 md:pt-4 px-8 md:px-8 lg:py-4'>
            <div className='flex justify-between items-center flex-wrap mb-6'>
              <h1 className='pb-6 text-[#5b5b5b] text-[16px] leading-[21px]'>
                DPP Request note
              </h1>
              <div className='flex flex-col pt-2  w-full'>
                <p className='text-left text-[#aaaaaa] text-[14px] leading-[18px]'>
                  {Case?.case_request?.reason}
                </p>
              </div>
              <div className='mt-4 flex  items-center  w-full py-[10px] px-[16px]   border-[1px] border-[#EAECF0] rounded-[8px]  cursor-pointer '>
                <div
                  className='flex  items-center '
                  onClick={() => {
                    setOpen(true);
                    setFileUrl(Case?.case_request?.file_path || "");
                  }}
                >
                  <img alt='' src={UPLOAD_FILE_PIN} srcSet='' />
                  <p className=' text-left text-[#667085] text-[14px] leading-[20px] font-[400] '>
                    <span className='text-[#11502F] font-bold'>
                      View attachment
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className='flex item-center gap-4 w-full'>
              <Button
                bgColor='bg-brand_blue'
                className=' mb-2  mt-4 w-full py-4 rounded-2xl'
                onClick={() => dispatch(setToggle_2(false))}
                textColor='text-white'
                title='Close'
                type='button'
              />
            </div>
          </div>
        </Zoom>
      </Modal>
    </div>
  );
}

export default Documents;
