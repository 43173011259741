import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "./components/Loader";
import { useAppSelector } from "./hooks";
import MainRoutes from "./Routes";
import { PrivateRoutes } from "./Routes/private";
import { globalLoading } from "./states/slices/authReducer";
import Login from "./features/onboarding/login";
import SignUp from "./features/onboarding/signUp";
import Verify from "./features/onboarding/verify";

function App() {
  const loading = useAppSelector(globalLoading);
  return (
    <>
      <Routes>
        <Route
          element={
            <PrivateRoutes>
              <MainRoutes />
            </PrivateRoutes>
          }
          path='/*'
        />

        <Route element={<Login />} path='/login' />
        {/* <Route element={<CauseList />} path='/cause-lists' /> */}

        <Route element={<SignUp />} path='/signup' />

        <Route element={<Verify />} path='/verify-email-code' />
        {/* <Route element={<NonVerified />} path='/non-verify' />
        <Route element={<VerifyOtp />} path='/email-Verification' />
        <Route element={<PasswordReset />} path='/password-reset' />
        <Route element={<ForgotPassword />} path='/forgot-password' /> */}

        <Route element={<Navigate replace to='/login' />} path='/' />
      </Routes>
      {loading && <Loader />}

      <Toaster position='top-right' reverseOrder={false} />
    </>
  );
}

export default App;
