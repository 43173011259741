/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Skeleton } from "@mui/material";
import moment from "moment";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { EMPTY } from "../../../assets";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import Typography from "../../../components/Typography";
import { getNotifications } from "../../../services/shared/service";

const Loader = lazy(() => import("../../../components/Loader/index"));

function Notification(this: {
  id: string;
  title: string;
  component: () => import("react/jsx-runtime").JSX.Element;
  path: string;
}) {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  const { data, refetch, isLoading } = useQuery(
    "notification",
    getNotifications,
  );

  useEffect(() => {
    refetch();
  }, []);

  const searchNotification = (event: any) => {
    setSearch(event.target.value);
  };

  const dataSearch =
    isLoading === true
      ? []
      : data?.filter((item: { [x: string]: { toString: () => string } }) =>
          Object.keys(item).some((key) =>
            item[key]
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase()),
          ),
        );

  return (
    <Suspense fallback={<Loader />}>
      <div className='px-[60px] py-[30px]'>
        <div className='w-full bg-white  pb-20 lg:p-8 shadow rounded-[5px] h-screen'>
          <div className='flex items-center justify-between w-full border-b border-[#EAECF0] pb-4'>
            <Typography textStyle='text-[22px] leading-[26px] font-bold'>
              Folders
            </Typography>
          </div>

          <div className='breadcrumb border-b border-[#EAECF0] py-4'>
            <Breadcrumb routes={[]} />
          </div>

          <div className='w-full flex flex-row justify-end lg:justify-between flex-wrap items-start  py-4 '>
            <div className=' lg:flex justify-between items-center w-full lg:w-1/3 mb-6 lg:mb-0'>
              <div className='flex items-center border border-[#D9D9D9] py-[8px] px-[10px] rounded-[15px]'>
                <input
                  className='bg-[transparent]  border-0 outline-0 w-[100%] placeholder:text-md placeholder:text-[#98A2B3] focus:outline-none focus:ring-0  '
                  onChange={searchNotification.bind(this)}
                  placeholder='Search'
                  type='text'
                  value={search}
                />

                <BsSearch />
              </div>
            </div>
          </div>

          {isLoading ? (
            <Box sx={{ pt: 0.5, pb: 2 }}>
              <Skeleton width='60%' />
              <Skeleton />
              <Skeleton height={100} variant='rectangular' width='100%' />
            </Box>
          ) : (
            <div className='flex flex-col'>
              {dataSearch?.length > 0 ? (
                dataSearch?.map(
                  (item: {
                    title: string;
                    body: string;
                    created_at: string;
                  }) => (
                    <div className='flex box-shadow mb-4 flex-col px-6 py-8 w-full bg-white rounded-lg  max-md:px-5 max-md:max-w-full'>
                      <div className='flex gap-5 max-md:flex-wrap max-md:max-w-full'>
                        <div className='flex-auto text-base font-bold text-black'>
                          {item?.title}
                        </div>
                        <div className='text-sm font-medium text-neutral-400'>
                          {moment(data?.created_at).format(
                            "MMM Do YYYY | hh:mm a",
                          )}
                        </div>
                      </div>
                      <div className='mt-4 text-sm font-medium text-neutral-400 max-md:max-w-full'>
                        {item?.body}
                      </div>
                    </div>
                  ),
                )
              ) : (
                <div className='text-center text-xs w-full'>
                  <div className='flex flex-col justify-center items-center h-52'>
                    <img alt='' className='h-32' src={EMPTY} />
                    <p className='text-center text-sm mt-4'>
                      You do not have a notifications
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <Tooltip id='view-tooltip' />
      </div>
    </Suspense>
  );
}

export default Notification;
