/* eslint-disable import/no-cycle */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

interface PartyTypeState {
  selectedPartyType: string | null;
}

const initialState: PartyTypeState = {
  selectedPartyType: null,
};

const partyTypeSlice = createSlice({
  name: "party_type",
  initialState,
  reducers: {
    setPartyType(state, action) {
      state.selectedPartyType = action.payload;
    },
    clearPartyType(state) {
      state.selectedPartyType = null;
    },
  },
});

export const { setPartyType, clearPartyType } = partyTypeSlice.actions;
export const getPartyType = (state: RootState) =>
  state.party_type.selectedPartyType;
export const partyReducer = partyTypeSlice.reducer;
