import CaseFolder from "../features/unitheads/casefolder";
import AddDocument from "../features/unitheads/casefolder/addDocument";
import Documents from "../features/unitheads/casefolder/documents";
import Notification from "../features/unitheads/notification";
import Overview from "../features/unitheads/overview";
import Settings from "../features/unitheads/settings";

export const UnitHeadsRoute = [
  {
    id: 1,
    title: "Overview",
    component: Overview,
    path: "/unit-head/overview",
  },
  {
    id: 2,
    title: "Overview",
    component: CaseFolder,
    path: "/unit-head/case-folders",
  },
  {
    id: 2,
    title: "Documents",
    component: Documents,
    path: "/unit-head/case-folders/documents",
  },
  {
    id: "10",
    title: "documents",
    component: AddDocument,
    path: "/unit-head/case-folders/documents/upload-document",
  },
  {
    id: 3,
    title: "Notifications",
    component: Notification,
    path: "/unit-head/notifications",
  },
  {
    id: 4,
    title: "Settings",
    component: Settings,
    path: "/unit-head/settings",
  },
];
