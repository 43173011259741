export enum UserType {
  LIAISON_OFFICER = "LIAISON_OFFICER",
  ATTORNEY_GENERAL = "ATTORNEY_GENERAL",
  LEGAL_OFFICER = "LEGAL_OFFICER",
  UNIT_HEAD = "UNIT_HEAD",
  DIRECTOR = "DIRECTOR",
}

export enum Status {
  PENDING = " PENDING",
  SUBMITTED = "SUBMITTED",
  AWAITING_SERVICE = "AWAITING_SERVICE",
  READY_FOR_ASSIGNMENT = "READY_FOR_ASSIGNMENT",
  PICKED_UP = "PICKED_UP",
  DELIVERED = "DELIVERED",
  SERVED = "SERVED",
  ASSIGNED = "ASSIGNED",
  RE_ASSIGNED = "RE_ASSIGNED",
  RECUSAL_REQUEST = "RECUSAL_REQUEST",
  TRANSFER = "TRANSFER",
  DPP_ADVICE = "DPP_ADVICE",
  RETURNED = "RETURNED",
  ADVICED = "ADVICED",
  RECALLED = "RECALLED",
  HISTORY = "HISTORY",
  ASSIGNED_HISTORY = "ASSIGNED_HISTORY",
  CONCLUDED = "CONCLUDED",
  RECUSED = "RECUSED",
}
