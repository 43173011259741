/* eslint-disable unicorn/prevent-abbreviations */
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks";
import AuthLayout, { AuthActions, AuthInput } from "../../layouts/auth";
import { login } from "../../services/auth/auth.service";
import { LoginPayloadTypes } from "../../services/auth/types";
import {
  selectRole,
  setRole,
  setToken,
  setUser,
} from "../../states/slices/authReducer";
import { handleNavigation } from "../../utils/functions/Navigation";

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const role = useAppSelector(selectRole);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const LoginMuation = useMutation(login);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
  ) => {
    const { value } = e.target;
    setForm((previousForm) => ({
      ...previousForm,
      [fieldName]: value,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload: LoginPayloadTypes = {
      email: form.email.trim(),
      password: form.password.trim(),
    };
    try {
      const response = await LoginMuation.mutateAsync(payload);

      if (response) {
        const { data, message } = response;
        toast.success(message, {
          duration: 7000, // Duration for which the toast is displayed (in milliseconds)
        });
        setIsLoading(false);

        dispatch(setUser(data));
        dispatch(setRole(data.role));
        dispatch(setToken(data.token));

        handleNavigation(data?.role, navigate);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message, {
        duration: 10_000,
      });
      setIsLoading(false);
    }
    // dispatch(setRole(form.email));

    // handleNavigation(role, navigate);
  };

  // eslint-disable-next-line no-console
  console.log(role);
  return (
    <AuthLayout>
      <div className='w-[458.35px] bg-white flex-col h-[445.89px] p-8 gap-y-7 rounded-lg flex items-center justify-start'>
        <span className='text-[24px] font-semibold text-black'>Log in</span>
        <div className='w-full flex flex-col items-start gap-y-8'>
          <AuthInput
            handleChange={(e) => handleChange(e, "email")}
            isOptional={false}
            name=''
            placeholder='Email address'
            type='email'
            value={form.email}
          />
          <div className='flex flex-col gap-2 w-full relative'>
            <AuthInput
              handleChange={(e) => handleChange(e, "password")}
              isOptional={false}
              name='password'
              placeholder='Password'
              type={show ? "text" : "password"}
              value={form.password}
            />
            {show ? (
              <FaEye
                className='absolute top-[20px] right-4 cursor-pointer text-gray-700 text-2xl'
                onClick={() => setShow(!show)}
              />
            ) : (
              <FaEyeSlash
                className='absolute top-[20px]  right-4 cursor-pointer text-gray-700 text-2xl'
                onClick={() => setShow(!show)}
              />
            )}
          </div>
        </div>

        <AuthActions
          actionText='Don’t have an account? Sign up.'
          btnText='Log in'
          handleAction={() => navigate("/signup")}
          handleClick={handleSubmit}
          isLoading={isLoading}
        />
        {/* 
        <Button
          bgColor='bg-[#217834]'
          className=' mb-2  mt-4  w-full py-4 rounded-full'
          isLoading={isLoading}
          onClick={handleSubmit}
          textColor='text-white'
          title='Log In'
          type='button'
        /> */}
      </div>
    </AuthLayout>
  );
}

export default Login;
