/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable unicorn/no-negated-condition */
/* eslint-disable no-restricted-syntax */
// export default AddNew;
/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useRef, useState } from "react";
import { Zoom } from "react-awesome-reveal";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  CHECK_GREEN,
  DPP_BACK,
  IS_SUCCESSFUL,
  UPLOAD_NEW,
  UPLOADED,
} from "../../../assets";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import Button from "../../../components/button";
import Typography from "../../../components/Typography";
import Modal from "../../../components/UI/modal";
import { addDocumentCaseApi } from "../../../services/unitHead/service";

const AddDocument = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  const [file, setFile] = useState<any>([]);
  const [required, setRequired] = useState<string>("");

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [title, setTitle] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(false);
  const [searchParams] = useSearchParams();
  const idParams = searchParams.get("id") || "";
  const name = searchParams.get("name");

  const [createModal, setCreateModal] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 50_000_000, // max 50MB size
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      setFile((previousFiles: any) => [
        ...previousFiles, // Keep the previously uploaded files
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ]);
    },
  });

  const removeFile = (index: number) => {
    setFile((previousFiles: any) =>
      previousFiles.filter((_: any, index_: number) => index_ !== index),
    );
  };

  // Create case documents
  const handleAddDocument = async (event_: any) => {
    event_.preventDefault();
    setIsLoading(true);

    if (!file || file.length === 0) {
      setRequired("required");
      toast.error("PDF is required.", {
        duration: 7000,
      });

      setIsLoading(false);

      return;
    }

    try {
      const formData = new FormData();

      // eslint-disable-next-line unicorn/no-array-for-each
      file.forEach((singleFile: File) => {
        formData.append("file_path", singleFile);
      });
      formData.append("title", title);
      const response = await addDocumentCaseApi(formData, idParams);

      if (response) {
        setRequired("");
        setCreateModal(true);
        toast.success(response.message, { duration: 7000 });
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || "An error occurred", {
        duration: 7000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const productFiles = file?.map((item: any, index: number) => (
    <div
      className='flex gap-6 items-start  w-full py-[24px] px-[16px]   border-[0.89px] border-[#C7C7CC] rounded-[8px]'
      key={index}
    >
      <div>
        <img alt='' src={UPLOADED} srcSet='' />
      </div>

      <div className='flex flex-col items-start gap-4 w-full'>
        <div className='flex justify-between items-center w-full'>
          <h1>{item.name}</h1>
          <img alt='' src={CHECK_GREEN} srcSet='' />
        </div>

        <div className='flex justify-between items-center gap-4'>
          <span className='rounded-full bg-brand_green h-2 p-2 w-[250px]' />
          <p className='text-[14px] leading-[20px]'>100%</p>
        </div>

        <div className='flex items-center gap-6'>
          <button
            className='text-[14px] leading-[21px] font-bold text-red-600 cursor-pointer'
            onClick={() => removeFile(index)}
            type='button'
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <div className='breadcrumb border-b border-[#EAECF0] py-4 flex items-center gap-2 bg-[#E0E0E0] px-[60px]'>
        <button className='' onClick={handleBack} type='button'>
          {/* <ArrowLeftIcon className='text-black w-3 ' /> */}
          <img alt='' src={DPP_BACK} srcSet='' />
        </button>

        <div className='flex items-center justify-between py-6'>
          <Breadcrumb routes={[]} />
        </div>
      </div>
      <div className='form container flex mx-auto p-20 w-full flex-col'>
        <Typography textStyle='text-[28px] leading-[36.96px] font-[600] text-center pb-6 '>
          Upload proof of service
        </Typography>

        <form
          action=''
          className='flex justify-center flex-col gap-6 items-center '
        >
          <div className='form-box_shadow bg-white  rounded-[5px] flex flex-col gap-4 justify-center w-[575px] p-[50px]'>
            <div className='mb-4'>
              <h1 className='pb-2 text-[16px] tracking-wide text-black'>
                Document name
              </h1>
              <input
                className='w-full text-sm sm:text-base placeholder-black px-[14px] py-[20px] rounded-[8px] border border-[#CACACA]  focus:outline-none focus:border-brand_blue focus:border'
                name='title'
                onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                  setTitle(text.target.value);
                }}
                placeholder='Enter your title '
                type='text'
                value={title}
              />
            </div>
            <div className='mb-4'>
              <div className='w-full flex flex-col gap-6'>
                {file.length > 0 ? (
                  productFiles
                ) : (
                  <div className='' {...getRootProps()}>
                    <div className='flex justify-center items-center  w-full py-[24px] px-[16px]   border-[1px] border-[#EAECF0] rounded-[8px]  cursor-pointer '>
                      <div className='flex flex-col items-center w-[60%] justify-center'>
                        <img alt='' src={UPLOAD_NEW} srcSet='' />
                        <p className=' text-center text-[#667085] text-[14px] leading-[20px] font-[400] '>
                          <span className='text-brand_green font-bold'>
                            Click to upload or drag and drop
                          </span>{" "}
                          Supported PDF files only (max, 10 MB)
                        </p>
                      </div>

                      <input
                        className='hidden'
                        multiple
                        name='file_upload'
                        ref={fileInputRef}
                        type='file'
                        {...getInputProps()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='w-full'>
              <Button
                bgColor='bg-brand_green'
                className={`w-full bg-brand_blue p-4 rounded-[4px] text-white text-[16px] ${file.length < 0 ? "opacity-50" : ""}`}
                isLoading={isLoading}
                onClick={handleAddDocument}
                textColor='text-white'
                title='Submit'
                type='button'
              />
            </div>
          </div>
        </form>

        <Modal
          close={() => {
            setCreateModal(false);
          }}
          maxWidth='xs'
          open={createModal}
        >
          <Zoom>
            <div className='p-12 flex flex-col justify-center'>
              <img alt='' className=' mx-auto' src={IS_SUCCESSFUL} srcSet='' />
              <div className='self-center text-lg my-8 font-semibold text-center text-[20px] leading-[26.4px]'>
                Submitted successfully
              </div>
              <button
                className='btn bg-brand_blue p-4 rounded-[4px] text-white text-[16px]'
                onClick={() => {
                  navigate(
                    `/laison-officer/case-folders/documents?id=${idParams}&name=${name}`,
                  );
                }}
                type='button'
              >
                Close
              </button>
            </div>
          </Zoom>
        </Modal>
      </div>
    </>
  );
};

export default AddDocument;
