/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";

function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className='bg-lawbg bg-cover w-full h-screen flex items-center justify-center'>
      <div>{children}</div>
    </div>
  );
}

export default AuthLayout;

interface AuthInputProps {
  value: string;
  placeholder: string;
  name: string;
  type: "email" | "text" | "password";
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isOptional: boolean;
}
export const AuthInput = (props: AuthInputProps) => {
  const { value, placeholder, name, handleChange, isOptional, type } = props;
  return (
    <div className='w-full flex px-3 py-3 flex-col rounded-lg items-end justify-end relative border-[0.89px] border-[#CACACA]'>
      {isOptional && (
        <span className='absolute italic right-0 top-0 text-xs text-gray-400'>
          Optional
        </span>
      )}

      <input
        className='text-sm text-black border-none w-full  font-normal placeholder:text-sm placeholder:text-[#CACACA] bg-transparent focus:outline-none relative focus:ring-0'
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </div>
  );
};

export const AuthActions = ({
  btnText,
  actionText,
  handleAction,
  handleClick,
  isLoading,
}: {
  btnText: string;
  actionText: string;
  isLoading: boolean | undefined;
  handleClick: () => void;
  handleAction: () => void;
}) => (
  <div className='flex flex-col items-center w-full gap-y-6'>
    {isLoading ? (
      <button
        className={`transition ease-in-out hover:-translate-y-1 hover:scale-105 p-4 flex justify-center items-center rounded-[4px] bg-[#08043F]   focus:outline-none focus:ring-2 disabled:pointer-events-none disabled:opacity-75   duration-300 text-white font-semibold text-[17.8px] j w-full  h-[50px] `}
        disabled={isLoading}
        type='button'
      >
        <svg
          className='w-5 h-5 mr-3 -ml-1 text-black animate-spin'
          fill='none'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            className='opacity-25'
            cx={12}
            cy={12}
            r={10}
            stroke='#fff'
            strokeWidth={4}
          />
          <path
            className='opacity-75'
            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            fill='#fff'
          />
        </svg>

        <span className='ml-2 text-[17.8px] leding-[23.5px]'>
          processing...
        </span>
      </button>
    ) : (
      <button
        className='w-full  h-[50px] flex items-center text-white font-semibold text-[17.8px] justify-center bg-[#08043F] rounded-lg'
        onClick={handleClick}
        type='button'
      >
        {btnText}
      </button>
    )}

    <span
      className='text-[12.46px] font-normal cursor-pointer text-black hover:underline'
      onClick={handleAction}
    >
      {actionText}
    </span>
  </div>
);
