/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import { useField } from "formik";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// interface InputProps {
// 	isPassword?: boolean | null | undefined;
// 	name?: string;
// 	type?: string;
// 	placeholder?: string;
// 	className?: string;
// 	// Additional props specific to the Input component
// 	// You can customize this interface according to your needs
// }
export const FormInput = (props) => (
  <div className=' mb-2  relative'>
    <input
      autoComplete='off'
      className={`border-[#CACACA] appearance-none border focus:ring-brand_green focus:border-brand_green placeholder:text-[14px] rounded-[4px] w-full py-3 px-3 text-[#98A2B3] mt-1 focus:outline-none focus:shadow-outline placeholder:text-[#98A2B3] placeholder:font-[400]  ${props.className}`}
      {...props}
      type={props.type || "text"}
    />
  </div>
);

const Input = ({ icon, ...props }) => {
  const [show, setShow] = useState(false);
  const [field, meta] = useField(props);

  return (
    <>
      {props.isPassword ? (
        <div className='mb-3 relative'>
          <input
            autoComplete='off'
            className={`border-[#CACACA] appearance-none focus:ring-brand_green focus:border-brand_green border placeholder:text-[14px] rounded-[4px] w-full py-3 px-3 text-[black] mt-1 focus:outline-none focus:shadow-outline placeholder:text-[#98A2B3] placeholder:font-[400] ${props.className}`}
            {...field}
            {...props}
            type={show ? "text" : "password"}
          />
          {show ? (
            <FaEye
              className='absolute top-4 right-4 cursor-pointer text-gray-700 text-2xl'
              onClick={() => setShow(!show)}
            />
          ) : (
            <FaEyeSlash
              className='absolute top-4 right-4 cursor-pointer text-gray-700 text-2xl'
              onClick={() => setShow(!show)}
            />
          )}
          {meta.touched && meta.error && (
            <span className='error text-[12px] text-red-500'>{meta.error}</span>
          )}
        </div>
      ) : (
        <div className='mb-3 relative'>
          <input
            autoComplete='off'
            className={`border-[#CACACA] h-[53px] focus:ring-brand_green focus:border-brand_green appearance-none border placeholder:text-[14px] rounded-[4px] w-full py-3 px-3 text-[black] font-light mt-1 focus:outline-none focus:shadow-outline placeholder:text-[#98A2B3] placeholder:font-[400] ${props.className}`}
            {...field}
            {...props}
          />
          {icon && (
            <span className='absolute top-4 right-4 cursor-pointer text-gray-700'>
              <img
                alt=''
                className={`${props.className}`}
                src={icon}
                srcSet=''
              />
            </span>
          )}
          {meta.touched && meta.error && (
            <span className='error text-[12px] text-red-500'>{meta.error}</span>
          )}
        </div>
      )}
    </>
  );
};

export default Input;
