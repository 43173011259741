import CaseFolder from "../features/legalofficers/casefolder";
import AddDocument from "../features/legalofficers/casefolder/addDocument";
import Documents from "../features/legalofficers/casefolder/documents";
import Notification from "../features/legalofficers/notification";
import Overview from "../features/legalofficers/overview";
import Settings from "../features/legalofficers/settings";

export const LegalOfficersRoute = [
  {
    id: 1,
    title: "Overview",
    component: Overview,
    path: "/legal-officer/overview",
  },
  {
    id: 2,
    title: "Overview",
    component: CaseFolder,
    path: "/legal-officer/case-folders",
  },
  {
    id: 2,
    title: "Documents",
    component: Documents,
    path: "/legal-officer/case-folders/documents",
  },
  {
    id: "10",
    title: "documents",
    component: AddDocument,
    path: "/legal-officer/case-folders/documents/upload-document",
  },

  {
    id: 3,
    title: "Notifications",
    component: Notification,
    path: "/legal-officer/notifications",
  },
  {
    id: 4,
    title: "Settings",
    component: Settings,
    path: "/legal-officer/settings",
  },
];
