import { NavigateFunction } from "react-router-dom";

import { UserType } from "../Enums";

export const handleNavigation = (data: string, navigate: NavigateFunction) => {
  switch (data) {
    case UserType.LIAISON_OFFICER: {
      navigate("/laison-officer/overview");
      break;
    }
    case UserType.ATTORNEY_GENERAL: {
      navigate("/attorney-general/overview");
      break;
    }
    case UserType.LEGAL_OFFICER: {
      navigate("/legal-officer/overview");
      break;
    }
    case UserType.UNIT_HEAD: {
      navigate("/unit-head/overview");
      break;
    }
    case UserType.DIRECTOR: {
      navigate("/director/overview");
      break;
    }

    default: {
      break;
    }
  }
};
