/* eslint-disable import/no-cycle */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

import { CaseDetailsResponse } from "../../services/Laywer/types";
import { RootState } from "../store";

interface FormState {
  partyInfo: unknown[];
  document: unknown[];
  case: CaseDetailsResponse | null;
  caseDetails: CaseDetailsResponse | null;
  toggle: boolean;
  toggle_2: boolean;
  toggle_3: boolean;
  toggle_4: boolean;
  toggle_5: boolean;
  singleParty: object;
  SingleDoc: object;
  reload: number;
  waiver: number;
}
const initialState: FormState = {
  partyInfo: [],
  document: [],
  case: null,
  caseDetails: null,
  toggle: false,
  toggle_2: false,
  toggle_3: false,
  toggle_4: false,
  toggle_5: false,
  singleParty: {},
  SingleDoc: {},
  reload: 0,
  waiver: 1,
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    addValue: (state, action: PayloadAction<any>) => {
      state.partyInfo.push(action.payload);
    },
    removeValue: (state, action: PayloadAction<any>) => {
      const filterPartyItems = state.partyInfo.filter(
        (item: any) => item.name !== action.payload.name,
      );
      state.partyInfo = filterPartyItems;
      toast.error(`${action.payload.name} is removed from the party info!`);
    },
    resetValue: (state) => {
      state.partyInfo.length = 0;
    },

    addDoc: (state, action: PayloadAction<any>) => {
      state.document.push(action.payload);
    },
    removeDoc: (state, action: PayloadAction<any>) => {
      const filterDocumentItems = state?.document?.filter(
        (item: any) => item?.title !== action.payload?.title,
      );
      state.document = filterDocumentItems;
      toast.error(
        `${action.payload?.title} is removed from the document upload!`,
      );
    },
    resetDoc: (state) => {
      state.document.length = 0;
    },
    updateCase: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-console
      console.log("Updating case with:", action.payload);
      state.case = action.payload;
    },
    updateCaseDetails: (state, action: PayloadAction<any>) => {
      state.caseDetails = action.payload;
    },
    setToggle: (state, action: PayloadAction<boolean>) => {
      state.toggle = action.payload;
    },
    setToggle_2: (state, action: PayloadAction<boolean>) => {
      state.toggle_2 = action.payload;
    },
    setToggle_3: (state, action: PayloadAction<boolean>) => {
      state.toggle_3 = action.payload;
    },
    setToggle_4: (state, action: PayloadAction<boolean>) => {
      state.toggle_4 = action.payload;
    },
    setToggle_5: (state, action: PayloadAction<boolean>) => {
      state.toggle_5 = action.payload;
    },
    setSingleParty: (state, action: PayloadAction<any>) => {
      state.singleParty = action.payload;
    },
    setSingleDoc: (state, action: PayloadAction<any>) => {
      state.SingleDoc = action.payload;
    },
    setReload: (state, action: PayloadAction<any>) => {
      state.reload = action.payload;
    },
    setWaiver: (state, action: PayloadAction<any>) => {
      state.waiver = action.payload;
    },
  },
});

export const {
  addValue,
  addDoc,
  resetValue,
  resetDoc,
  updateCase,
  updateCaseDetails,
  setToggle,
  setToggle_2,
  setToggle_3,
  setToggle_4,
  setToggle_5,
  setSingleParty,
  setSingleDoc,
  setReload,
  removeValue,
  setWaiver,
  removeDoc,
} = formSlice.actions;
export const getPartyInfo = (state: RootState) => state.form.partyInfo;
export const getWaiver = (state: RootState) => state.form.waiver;
export const getSelectedCase = (state: RootState) => state.form.case;
export const getSelectedCaseDetails = (state: RootState) =>
  state.form.caseDetails;
export const getDocument = (state: RootState) => state.form.document;
export const getSingleParty = (state: RootState) => state.form.singleParty;
export const toggleState = (state: RootState) => state.form.toggle;
export const toggleState_2 = (state: RootState) => state.form.toggle_2;
export const toggleState_3 = (state: RootState) => state.form.toggle_3;
export const toggleState_4 = (state: RootState) => state.form.toggle_4;
export const toggleState_5 = (state: RootState) => state.form.toggle_5;
export const getSingleDoc = (state: RootState) => state.form.SingleDoc;
export const reload = (state: RootState) => state.form.reload;
export const formReducer = formSlice.reducer;
