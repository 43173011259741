/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../index.css";

import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { BELL, LOGO_DPP, LOGOUT } from "../../assets";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { UserPayload } from "../../services/auth/types";
import { getNotifications } from "../../services/shared/service";
import {
  selectRole,
  selectUser,
  setRole,
} from "../../states/slices/authReducer";
import { getBackgroundColor } from "../../utils";
import { UserType } from "../../utils/Enums";
import {
  AttorneyHeaderRouter,
  DirectorsHeaderRouter,
  HeaderDataTypes,
  LaisonHeaderRouter,
  LegalOfficerHeaderRouter,
  UnitHeaderRouter,
} from "./headerData";

interface HeaderTypes {
  onMenu?: any;
}

function Header(props: HeaderTypes) {
  const location = useLocation();
  const role = useAppSelector(selectRole);
  const user: UserPayload = useAppSelector(selectUser);
  const colors: string = getBackgroundColor(user?.name);
  const [hasUnread, setHasUnread] = useState(false);
  const navigate = useNavigate();

  const now = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[now.getDay()];
  const month = months[now.getMonth()];
  const dayOfMonth = now.getDate();
  const year = now.getFullYear();

  const formattedDate = `${dayOfWeek} ${month} ${dayOfMonth}, ${year}`;
  const dispatch = useAppDispatch();
  const { data, refetch } = useQuery("notification", getNotifications);

  useEffect(() => {
    refetch();
  }, []);
  // eslint-disable-next-line no-console
  // console.log(data);

  useEffect(() => {
    if (data?.length > 0) {
      setHasUnread(true);
    }
  }, [data]);

  const handleNotificationClick = () => {
    setHasUnread(false);
  };

  const isActive = (item: HeaderDataTypes) =>
    location.pathname === item.route_to;

  return (
    <div className='w-full bg-[#08043F] border-b border-gray-800 shadow-header px-[96px] py-5 flex items-center justify-between'>
      <div className='flex items-center gap-x-6 w-full'>
        <img alt='' className='w-[105px] h-[51.08px]' src={LOGO_DPP} />
        <div className='flex items-center justify-center gap-x-4'>
          {role === UserType.LIAISON_OFFICER &&
            LaisonHeaderRouter.map((item) => (
              <Link
                className={`flex items-center gap-x-3 w-[140px] h-[44px]  text-[16px] px-2 ${isActive(item) ? "bg-[#23A863] border-[0.5px] border-[#4674E585] text-white" : " text-[#FFFFFF66]"} rounded-lg `}
                key={item.id}
                to={item.route_to}
              >
                <div>
                  {isActive(item) ? item.icon_active : item.icon_inactive}
                </div>

                <span>{item.title}</span>
              </Link>
            ))}
          {role === UserType.UNIT_HEAD &&
            UnitHeaderRouter.map((item) => (
              <Link
                className={`flex items-center gap-x-3 w-[140px] h-[44px]  text-[16px] px-2 ${isActive(item) ? "bg-[#23A863] border-[0.5px] border-[#4674E585] text-white" : " text-gray-400"} rounded-lg `}
                key={item.id}
                to={item.route_to}
              >
                <div>
                  {isActive(item) ? item.icon_active : item.icon_inactive}
                </div>

                <span>{item.title}</span>
              </Link>
            ))}
          {role === UserType.ATTORNEY_GENERAL &&
            AttorneyHeaderRouter.map((item) => (
              <Link
                className={`flex items-center gap-x-3 w-[140px] h-[44px]  text-[16px] px-2 ${isActive(item) ? "bg-[#23A863] border-[0.5px] border-[#4674E585] text-white" : " text-gray-400"} rounded-lg `}
                key={item.id}
                to={item.route_to}
              >
                <div>
                  {isActive(item) ? item.icon_active : item.icon_inactive}
                </div>

                <span>{item.title}</span>
              </Link>
            ))}
          {role === UserType.LEGAL_OFFICER &&
            LegalOfficerHeaderRouter.map((item) => (
              <Link
                className={`flex items-center gap-x-3 w-[140px] h-[44px]  text-[16px] px-2 ${isActive(item) ? "bg-[#23A863] border-[0.5px] border-[#4674E585] text-white" : " text-gray-400"} rounded-lg `}
                key={item.id}
                to={item.route_to}
              >
                <div>
                  {isActive(item) ? item.icon_active : item.icon_inactive}
                </div>

                <span>{item.title}</span>
              </Link>
            ))}
          {role === UserType.DIRECTOR &&
            DirectorsHeaderRouter.map((item) => (
              <Link
                className={`flex items-center gap-x-3 w-[140px] h-[44px]  text-[16px] px-2 ${isActive(item) ? "bg-[#23A863] border-[0.5px] border-[#4674E585] text-white" : " text-gray-400"} rounded-lg `}
                key={item.id}
                to={item.route_to}
              >
                <div>
                  {isActive(item) ? item.icon_active : item.icon_inactive}
                </div>

                <span>{item.title}</span>
              </Link>
            ))}
        </div>
      </div>
      <div className='flex items-center w-[550px] gap-x-10 hover:scale-100 transition-all duration-200'>
        <div
          className='flex items-center gap-x-4 cursor-pointer'
          onClick={() => {
            dispatch(setRole(""));
            navigate("/login");
          }}
        >
          <img alt='' className='w-[17px] h-[24px]' src={LOGOUT} />
          <span className='text-[16px] font-normal text-[#FFFFFF66]'>
            Log out
          </span>
        </div>
        <div className='flex items-center gap-x-5'>
          <div>
            <img alt='' className='w-[26px] h-[26px]' src={BELL} />
          </div>
          <div className='flex items-center gap-x-3'>
            {colors && (
              <div
                className='w-12 h-12  border-4  rounded-full flex justify-center items-center text-white font-bold'
                style={{
                  backgroundColor: colors,
                }}
              >
                {user?.name[0]}
                {/* {user?.first_name[0]} */}
              </div>
            )}
            {/* <img alt='' className='w-[41px] h-[41px]' src={BOY} /> */}
            <div className='flex flex-col items-start'>
              <span className='text-[16px] font-medium text-white'>
                {user?.name || "Undefined"}
              </span>
              <span className='text-[12px] font-medium text-white'>
                {user?.email || "Undefined"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
