/* eslint-disable unicorn/prevent-abbreviations */
import toast from "react-hot-toast";

import { API } from "../../config/index";
import { setGlobalLoading } from "../../states/slices/authReducer";
import {
  setToggle_3,
  toggleState_3,
} from "../../states/slices/partyInfo.slice";
import path from "./path";
import { AnalyticsTypes } from "./types";

export const SendCaseApi = async (
  data: string,
  dispatch: any,
  user_id: any,
  role: any,
  setSuccessful: (value: boolean) => void,
) => {
  dispatch(setGlobalLoading(true));

  try {
    const response = await API.post(path.submitCaseFolder(data), {
      role,
      user_id,
    });
    if (response) {
      toast.success(response?.data?.message);
      dispatch(setGlobalLoading(false));
      dispatch(toggleState_3(false));
      setSuccessful(true);
    }
  } catch (error: any) {
    dispatch(setGlobalLoading(false));
    toast.error(error?.response?.data.message);
  }
};
export const ReturnCasefolderApi = async (
  data: string,
  dispatch: any,
  role: any,
) => {
  dispatch(setGlobalLoading(true));

  try {
    const response = await API.post(path.return(data), {
      role,
    });
    if (response) {
      dispatch(setGlobalLoading(false));
      toast.success(response.data.message, {
        duration: 10_000,
      });
      dispatch(setToggle_3(false));
    }
  } catch (error: any) {
    dispatch(setGlobalLoading(false));
    toast.error(error.response.data.message, {
      duration: 10_000,
    });
  }
};
export const getDashboardAnalytics = async () => {
  const response: AnalyticsTypes = await API.get(path.dashboard);
  return response?.data?.data;
};

export const addDocumentCaseApi = async (payload: any, id: string | any) => {
  const response = await API.post(path.addDocument(id), payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response?.data;
};
