export default {
  dashboard: "attorney-general/dashboard",

  submitCaseFolder: (case_folder_id: string) =>
    `attorney-general/case-folders/${case_folder_id}/submit`,
  return: (case_folder_id: string) =>
    `director/case-folders/${case_folder_id}/return`,

  addDocument: (case_folder_id: string) =>
    `attorney-general/case-folders/${case_folder_id}/add-document`,
};
