/* eslint-disable react/no-array-index-key */
import { Skeleton } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { Zoom } from "react-awesome-reveal";
import { BsEye } from "react-icons/bs";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import {
  BROWN_FRAME,
  CALENDER,
  EMPTY,
  FILTER_LINES,
  FRAME_1,
  GREEN_FRAME,
} from "../../../assets";
import Button from "../../../components/button";
import Modal from "../../../components/UI/modal";
import { useAppSelector } from "../../../hooks";
import { OverLay } from "../../../layouts/dashboard";
import { UserPayload } from "../../../services/auth/types";
import { getDashboardAnalytics } from "../../../services/Director/service";
import { ShowCaseFolder } from "../../../services/shared/service";
import { selectUser } from "../../../states/slices/authReducer";
import {
  getSelectedCaseDetails,
  setToggle,
  toggleState,
  updateCase,
  updateCaseDetails,
} from "../../../states/slices/partyInfo.slice";
import { computedDppStatusStyle } from "../../../utils/helperFunction";

function Overview() {
  const { data: analytics, refetch } = useQuery(
    "analytcs",
    getDashboardAnalytics,
  );

  useEffect(() => {
    refetch();
  }, []);
  const toggle = useSelector(toggleState);
  const dispatch = useDispatch();
  const CaseDetails = useAppSelector(getSelectedCaseDetails);
  const user: UserPayload = useAppSelector(selectUser);

  const handleViewDetails = async (id: any) => {
    dispatch(setToggle(true));
    dispatch(updateCase(id));

    // Fetch and dispatch the case details
    const caseDetails = await ShowCaseFolder(id, dispatch);
    dispatch(updateCaseDetails(caseDetails));
  };
  return (
    <OverLay>
      <div className='text-white py-10 px-[96px]'>
        <div className='flex flex-col gap-y-8'>
          <span className='text-[#FFFFFF]/40 font-medium text-[16px]'>
            Overview
          </span>
          <span className='text-[#F9F9F9] text-[30px] font-semibold'>
            Welcome back, {user?.name}{" "}
          </span>
        </div>

        <div className='grid grid-cols-3 gap-5 mt-7'>
          <div className=' h-[167px] flex items-start gap-x-3 p-7 bg-white rounded-md shadow-card'>
            <img alt='' src={FRAME_1} />
            <div className='flex flex-col gap-y-3'>
              <span className='text-[15px] font-medium text-black'>
                Number of submitted folders
              </span>
              <div className='flex flex-col gap-y-1'>
                <span className='text-[30px] font-medium text-black'>
                  {analytics?.case_folder_submitted_count}
                </span>
                {/* <span className='text-xs font-medium text-[#717273B8]'>
                  <span className='text-[#00A573B8]'>+12%</span> from last month
                </span> */}
              </div>
            </div>
          </div>
          <div className=' h-[167px] flex items-start gap-x-3 p-7 bg-white rounded-md shadow-card'>
            <img alt='' src={GREEN_FRAME} />
            <div className='flex flex-col gap-y-3'>
              <span className='text-[15px] font-medium text-black'>
                Number of folders attended to
              </span>
              <div className='flex flex-col gap-y-1'>
                <span className='text-[30px] font-medium text-black'>
                  {analytics?.case_folder_attended_count}
                </span>
                {/* <span className='text-xs font-medium text-[#717273B8]'>
                  <span className='text-[#00A573B8]'>+12%</span> from last month
                </span> */}
              </div>
            </div>
          </div>
          <div className=' h-[167px] flex items-start gap-x-3 p-7 bg-white rounded-md shadow-card'>
            <img alt='' src={BROWN_FRAME} />
            <div className='flex flex-col gap-y-3'>
              <span className='text-[15px] font-medium text-black'>
                Number of folders yet to be attended to
              </span>
              <div className='flex flex-col gap-y-1'>
                <span className='text-[30px] font-medium text-black'>
                  {analytics?.case_folder_pending_count}
                </span>
                {/* <span className='text-xs font-medium text-[#717273B8]'>
                  <span className='text-[#00A573B8]'>+12%</span> from last month
                </span> */}
              </div>
            </div>
          </div>
        </div>

        <div className='table-overview bg-white rounded-[15px] border border-[#c5c5c5]/50 box-shadow mt-6 p-[40px] flex flex-col gap-[20px]'>
          <span className='text-2xl font-semibold text-black'>
            Folder history
          </span>
          <div className='flex items-center gap-x-3 justify-end'>
            <div className='border border-[#D0D5DD] flex items-center gap-x-2 shadow-[0px 1px 2px 0 rgba(16, 24, 40, 0.05)] rounded-lg p-2 w-[200px]'>
              <img alt='' className='w-[20px] h-[20px]' src={CALENDER} />
              <span className='text-sm font-semibold text-[#98A2B3]'>
                Start date – End date
              </span>
            </div>
            <div className='border border-[#D0D5DD] flex items-center gap-x-2 shadow-[0px 1px 2px 0 rgba(16, 24, 40, 0.05)] rounded-lg p-2 w-[110px]'>
              <img alt='' className='w-[20px] h-[20px]' src={FILTER_LINES} />
              <span className='text-[#344054] font-semibold text-sm'>
                Filters
              </span>
            </div>
          </div>

          <div className='mt-8'>
            <div className='table w-full overflow-auto'>
              {analytics !== undefined && analytics.case_folders?.length > 0 ? (
                <div className='  border-t-black border-[#F0F0F0] rounded-b-xl relative '>
                  <table className='w-full text-sm text-left'>
                    <thead className='text-[#667085] text-[12px] font-medium bg-[#F9FAFB]'>
                      <tr>
                        <th className='py-3 px-6' scope='col'>
                          SN
                        </th>
                        <th className='py-3 px-6' scope='col'>
                          Date Received
                        </th>
                        <th className='py-3 px-6' scope='col'>
                          Title
                        </th>
                        <th className='py-3 px-6' scope='col'>
                          Suit number
                        </th>
                        <th className='py-3 px-6' scope='col'>
                          Details
                        </th>
                        <th className='py-3 px-6' scope='col'>
                          Query
                        </th>

                        <th className='py-3 px-6' scope='col'>
                          Stage
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {analytics.case_folders
                        ?.sort(
                          (a, b) =>
                            new Date(b.created_at).getTime() -
                            new Date(a.created_at).getTime(),
                        )
                        .slice(0, 5)
                        .map((item, index) => (
                          <tr className='bg-white border-b' key={item.id}>
                            <td className='py-4 px-6 text-[#667085] text-[14px] leading-[18px]'>
                              {index + 1}
                            </td>
                            <td className='py-4 px-6 text-[#667085] text-[14px] leading-[18px]'>
                              {moment(item?.created_at).format("Do MMM YY")}
                            </td>
                            <td className='py-4 px-6 text-[#667085] text-[14px] leading-[18px]'>
                              {item?.title}
                            </td>
                            <td className='py-4 px-6 text-[#667085] text-[14px] leading-[18px]'>
                              {item?.suit_number}
                            </td>
                            <td>
                              <button
                                className='flex items-center gap-2 text-[#667085] text-[14px] leading-[18px]'
                                onClick={() => handleViewDetails(item?.id)}
                                type='button'
                              >
                                <span>View</span> <BsEye />
                              </button>
                            </td>
                            <td className='px-4 py-2 text-[#10C181]  text-[13px] font-medium'>
                              {item?.case_query?.status === "QUERIED" ? (
                                <span className='text-[#FA4E4E]'>Queried</span>
                              ) : (
                                <span>N/A</span>
                              )}
                            </td>
                            <td
                              className={`px-4 py-2  text-[13px] font-[400] `}
                            >
                              <span
                                className={`  ${computedDppStatusStyle(item)}`}
                              >
                                {" "}
                                {item?.dpp_status}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : analytics !== undefined &&
                analytics.case_folders?.length < 1 ? (
                <div className='text-center text-xs w-full'>
                  <div className='flex flex-col justify-center items-center h-52'>
                    <img alt='' className='h-32' src={EMPTY} />
                    <p className='text-center text-sm mt-4'>
                      You do not have any case folder
                    </p>
                  </div>
                </div>
              ) : (
                <div className='w-full mb-3 px-8 flex justify-between items-center'>
                  <Skeleton
                    className='w-[50px] rounded-full mb-4'
                    height={50}
                    variant='rectangular'
                  />
                  <div className='w-[80%]'>
                    <Skeleton
                      className='w-full mb-4'
                      height={20}
                      variant='rectangular'
                    />
                    <Skeleton
                      className='w-[80%] mb-4'
                      height={20}
                      variant='rectangular'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        close={() => {
          dispatch(setToggle(false));
        }}
        maxWidth='xs'
        open={toggle}
      >
        <Zoom>
          <div className='px-12'>
            <div className='leading-[31px] text-[24px] font-[600] text-center'>
              Case folder details
            </div>
            <div className='mt-9 leading-[18px] text-[14px] font-[400]'>
              Case title
            </div>
            <div className='mt-4 font-medium leading-[21px] text-[16px] '>
              {CaseDetails?.title}
            </div>

            <hr className='my-6' />

            <div className='py-1 text-[16px] leading-[21px] font-[600]  capitalize'>
              INFORMATION OF PARTIES
            </div>

            {CaseDetails?.parties?.length > 0 &&
              CaseDetails?.parties?.map((item: any, index: number) => (
                <>
                  <div className='my-5 font-semibold text-sm capitalize'>
                    {item.title}
                  </div>
                  <div
                    className='flex gap-5 justify-between mt-2 mb-5 text-sm'
                    key={index}
                  >
                    <div className='flex flex-col'>
                      <div>Full name</div>
                      <div className='mt-7'>Email address</div>
                      <div className='mt-7'>Phone number</div>
                      <div className='mt-7'>Whatsapp number</div>
                      <div className='mt-7' />
                    </div>
                    <div className='flex flex-col self-start font-medium'>
                      <div className='flex flex-col items-end pl-9'>
                        <div>{item.name}</div>
                        <div className='self-start mt-6'>{item.email}</div>
                        <div className='mt-7'>{item.phone_number || "N/A"}</div>
                        <div className='mt-7'>
                          {item.whatsapp_number || "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            <hr className='my-6' />

            <div className='py-1 text-[16px] leading-[21px] font-[600]  capitalize'>
              <div className='py-1 text-[16px] leading-[21px] font-[600]  capitalize'>
                DETAILS OF COUNSEL
              </div>

              {CaseDetails?.counsel_details?.length > 0 &&
                CaseDetails?.counsel_details?.map(
                  (item: any, index: number) => (
                    <>
                      <div className='my-5 font-semibold text-sm capitalize'>
                        {item.title}
                      </div>
                      <div
                        className='flex gap-5 justify-between mt-2 mb-5 text-sm'
                        key={index}
                      >
                        <div className='flex flex-col'>
                          <div>Full name</div>
                          <div className='mt-7'>Email address</div>
                          <div className='mt-7'>Phone number</div>
                          <div className='mt-7'>Whatsapp number</div>
                          <div className='mt-7' />
                        </div>
                        <div className='flex flex-col self-start font-medium'>
                          <div className='flex flex-col items-end pl-9'>
                            <div>{item.name}</div>
                            <div className='self-start mt-6'>{item.email}</div>
                            <div className='mt-7'>
                              {item.phone_number || "N/A"}
                            </div>
                            <div className='mt-7'>
                              {item.whatsapp_number || "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                )}
            </div>

            <Button
              bgColor='bg-brand_blue'
              className=' mb-2  mt-4 w-full py-4 rounded-2xl'
              onClick={() => dispatch(setToggle(false))}
              textColor='text-white'
              title='Close'
              type='button'
            />
          </div>
        </Zoom>
      </Modal>
    </OverLay>
  );
}

export default Overview;
