/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Formik } from "formik";
import React, { lazy, Suspense, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { Zoom } from "react-reveal";
import { Tooltip } from "react-tooltip";

import { EDIT_PROFILE } from "../../../assets";
import Button from "../../../components/button";
import Input from "../../../components/form/Input";
import Typography from "../../../components/Typography";
import { useAppSelector } from "../../../hooks";
import {
  UpdatePasswordApi,
  UpdateProfileApi,
} from "../../../services/auth/auth.service";
import { selectRole, selectUser } from "../../../states/slices/authReducer";

const Loader = lazy(() => import("../../../components/Loader/index"));

function Settings() {
  const user = useAppSelector(selectUser);
  const role = useAppSelector(selectRole);
  const [type, setType] = useState("personal");
  const [files, setFiles] = useState<any>([]);
  const [barFile, setBarFile] = useState<any>([]);
  const updateProfileMutation = useMutation(UpdateProfileApi);
  const updatePasswordMutation = useMutation(UpdatePasswordApi);

  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone_number: user?.phone_number || "",
    whatsapp_number: user?.whatsapp_number || "",
    scn: user?.scn || "",
    address: user?.address || "",
  });

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 2_000_000,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const profilePic =
    files?.length > 0 ? (
      <>
        {files.map((file: any, index: any) => (
          <img
            alt=''
            className='h-20 w-20 rounded-full'
            key={index}
            src={files[0].preview}
          />
        ))}
      </>
    ) : (
      <img
        alt=''
        className='h-20 w-20 rounded-full'
        src={user?.profile_photo}
      />
    );

  return (
    <Suspense fallback={<Loader />}>
      <div className='px-[60px] py-[30px]'>
        <div className='w-full bg-white  pb-20 lg:p-8 shadow rounded-[5px]'>
          <div className='flex items-center justify-between w-full border-b border-[#EAECF0] pb-4'>
            <Typography textStyle='text-[22px] leading-[26px] font-bold'>
              Settings
            </Typography>
          </div>

          <div className='border-[#EAECF0] border-b '>
            <div className='flex items-center  space-x-6 text-[#98A2B3]'>
              <button
                className={`${type === "personal" ? "border-b border-[#101828] text-[#101828]" : ""} text-[16px] leading-[19px]  py-4`}
                onClick={() => setType("personal")}
                type='button'
              >
                Personal Information
              </button>

              <button
                className={`${type === "password" ? "border-b border-[#101828] text-[#101828]" : ""} text-[16px] leading-[19px]  py-4`}
                onClick={() => setType("password")}
                type='button'
              >
                Password Management
              </button>
            </div>
          </div>

          <div className='w-full pt-4 '>
            <Zoom triggerOnce={false}>
              <div>
                {type === "personal" && (
                  <Formik
                    initialValues={userData}
                    // eslint-disable-next-line react/jsx-sort-props
                    enableReinitialize
                    // validationSchema={signupSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const nameParts = values.name.trim().split(" ");
                      const firstname = nameParts[0];
                      const lastname = nameParts.slice(1).join(" ");
                      const fullname = `${firstname} ${lastname}`.trim();
                      const formData: any = new FormData();

                      formData.append("name", fullname);
                      formData.append("firm_name", values.name);
                      formData.append("email", values.email);

                      formData.append("phone_number", values.phone_number);
                      formData.append("address", values.address);
                      formData.append(
                        "whatsapp_number",
                        values.whatsapp_number,
                      );
                      formData.append("scn", values.scn);

                      if (files?.length > 0) {
                        formData.append("call_to_bar_certificate", files[0]);
                      }
                      if (barFile?.length > 0) {
                        formData.append("call_to_bar_certificate", barFile[0]);
                      }

                      try {
                        const response =
                          await updateProfileMutation.mutateAsync(formData);

                        if (response) {
                          toast.success(response.message);
                          setUserData({
                            name: response.data.name,
                            email: response.data.email,
                            phone_number: response.data.phone_number,
                            whatsapp_number: response.data.whatsapp_number,
                            scn: response.data.scn || "",
                            address: response.data.address || "",
                          });
                        }
                      } catch (error: any) {
                        toast.error(error?.response?.data?.message);
                      }
                    }}
                  >
                    {({
                      handleSubmit,
                      isSubmitting,
                      values,
                      handleChange,
                      /* and other goodies */
                    }) => (
                      <Form className='  bg-white border border-[#eaecf0] rounded-[15px] mb-12 max-w-full'>
                        {type === "personal" && (
                          <div className='w-full '>
                            <div className='bg-[#F5F5F5] p-4 w-full rounded-t-[14px]'>
                              <h1 className='text-[16px] leading-[19px] px-6'>
                                Personal Information
                              </h1>
                            </div>

                            <div className='grid grid-cols-2 gap-6 p-[42px] '>
                              <div className='flex flex-col   gap-2   w-full '>
                                <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                  Name
                                </h1>
                                <Input
                                  icon={EDIT_PROFILE}
                                  name='name'
                                  onChange={handleChange}
                                  type='text'
                                  value={values.name}
                                />
                              </div>
                              <div className='flex flex-col  gap-2 w-full '>
                                <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                  Email address
                                </h1>
                                <Input
                                  icon={undefined}
                                  name='email'
                                  readOnly
                                  type='email'
                                  value={values.email}
                                />
                              </div>

                              <div className='flex flex-col  gap-2 w-full '>
                                <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                  SCN (Supreme Court Number)
                                </h1>

                                <Input
                                  icon={undefined}
                                  name='scn'
                                  readOnly
                                  type='text'
                                  value={values.scn}
                                />
                              </div>
                              <div className='flex flex-col  gap-2 w-full '>
                                <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                  Law firm
                                </h1>

                                <Input
                                  icon={EDIT_PROFILE}
                                  name='firm_name'
                                  onChange={handleChange}
                                  placeholder='Enter name of firm'
                                  type='text'
                                  value='firm name'
                                />
                              </div>
                              <div className='flex flex-col  gap-2 w-full '>
                                <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                  Address of service
                                </h1>

                                <Input
                                  icon={EDIT_PROFILE}
                                  name='address'
                                  onChange={handleChange}
                                  placeholder='Enter address'
                                  type='text'
                                  value={values.address}
                                />
                              </div>
                              <div className='flex flex-col  gap-2 w-full '>
                                <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                  Telephone number
                                </h1>

                                <Input
                                  icon={EDIT_PROFILE}
                                  name='phone_number'
                                  onChange={handleChange}
                                  placeholder='Enter Telephone number'
                                  type='text'
                                  value={values.phone_number}
                                />
                              </div>
                              <div className='flex flex-col  gap-2 w-full '>
                                <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                  Whatsapp number
                                </h1>

                                <Input
                                  icon={EDIT_PROFILE}
                                  name='whatsapp_number'
                                  onChange={handleChange}
                                  placeholder='Enter whatsapp number'
                                  type='text'
                                  value={values.whatsapp_number}
                                />
                              </div>
                              <Zoom>
                                <div
                                  className='flex flex-col  gap-2'
                                  {...getRootProps()}
                                >
                                  <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                    Upload Profile Picture
                                  </h1>
                                  <div className='w-full'>
                                    {files.length > 0 ? (
                                      profilePic
                                    ) : (
                                      <label className='flex  justify-start items-center border-[#CACACA] appearance-none border placeholder:text-[14px] rounded-[4px] w-full py-3 px-3 text-[#98A2B3] mt-1 focus:outline-none focus:shadow-outline placeholder:text-[#98A2B3] placeholder:font-[400]'>
                                        <p className='flex justify-between items-center w-full'>
                                          <span className='text-sm'>
                                            Select to upload picture
                                          </span>
                                          <a className='h-8 px-6  bg-[#BFF2D8] pt-1 pb-2 self-end  font-normal items-center text-xs text-brand_green bg-brand_green/20 border border-BRAND-200 rounded-md '>
                                            upload
                                          </a>
                                        </p>

                                        <input
                                          className='hidden'
                                          name='file_upload'
                                          type='file'
                                          {...getInputProps()}
                                        />
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </Zoom>
                            </div>
                          </div>
                        )}

                        <div className='px-[32px]'>
                          <Button
                            bgColor='bg-brand_blue'
                            className=' mb-8 w-full lg:w-[25%] py-3 rounded-2xl ml-auto'
                            isLoading={isSubmitting}
                            onClick={handleSubmit}
                            textColor='text-white'
                            title='Update Profile'
                            type='button'
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}

                {type === "password" && (
                  <Formik
                    initialValues={{
                      current_password: "",
                      new_password: "",
                      confirm_new_password: "",
                    }}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      if (values.new_password !== values.confirm_new_password) {
                        toast.error(
                          "New password and confirm password do not match",
                        );
                        return;
                      }

                      const formData = new FormData();
                      formData.append(
                        "current_password",
                        values.current_password,
                      );
                      formData.append("new_password", values.new_password);
                      formData.append(
                        "confirm_new_password",
                        values.confirm_new_password,
                      );

                      try {
                        const response =
                          await updatePasswordMutation.mutateAsync(formData);
                        if (response) {
                          toast.success(response.message);
                        }
                      } catch (error: any) {
                        toast.error(error?.response?.data?.message);
                      } finally {
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({ handleSubmit, isSubmitting, values, handleChange }) => (
                      <Form className='  bg-white border border-[#eaecf0] rounded-[15px] mb-12 max-w-full'>
                        <div className='  '>
                          <div className='bg-[#F5F5F5] p-4 w-full rounded-t-[14px]'>
                            <h1 className='text-[16px] leading-[19px] px-6'>
                              Password
                            </h1>
                          </div>

                          <div className='grid grid-cols-2 gap-6 p-[42px] '>
                            <div className='flex flex-col  gap-2 w-full '>
                              <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                Current Password
                              </h1>
                              <Input
                                icon={EDIT_PROFILE}
                                name='current_password'
                                onChange={handleChange}
                                placeholder='Current Password'
                                type='password'
                                value={values.current_password}
                              />
                            </div>
                            <div className='flex flex-col  gap-2 w-full '>
                              <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                New Password
                              </h1>
                              <Input
                                icon={EDIT_PROFILE}
                                name='new_password'
                                onChange={handleChange}
                                placeholder='New Password'
                                type='password'
                                value={values.new_password}
                              />
                            </div>
                            <div className='flex flex-col  gap-2 w-full '>
                              <h1 className='text-[#98A2B3] text-[14px] font-[300] leading-[18px]'>
                                Confirm Password
                              </h1>
                              <Input
                                icon={EDIT_PROFILE}
                                name='confirm_new_password'
                                onChange={handleChange}
                                placeholder='Confirm Password'
                                type='password'
                                value={values.confirm_new_password}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='px-[32px]'>
                          <Button
                            bgColor='bg-brand_blue'
                            className=' mb-8 w-full lg:w-[25%] py-3 rounded-2xl ml-auto'
                            isLoading={isSubmitting}
                            onClick={handleSubmit}
                            textColor='text-white'
                            title='Update Password'
                            type='submit'
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </Zoom>
          </div>
        </div>

        <Tooltip id='view-tooltip' />
      </div>
    </Suspense>
  );
}

export default Settings;
