import toast from "react-hot-toast";

import { API } from "../../config";
import { setGlobalLoading } from "../../states/slices/authReducer";
import {
  updateCase,
  updateCaseDetails,
} from "../../states/slices/partyInfo.slice";
import { Store } from "../../states/store";
import {
  CaseDetailsResponse,
  CaseHoldersResponse,
  CreateDocumentTypes,
  GetCaseFolderResponseTypes,
  JudicialDivisionTypes,
  SubjectMatterTypes,
} from "../Laywer/types";
import path from "./paths";

export const getNotifications = async () => {
  const id = Store.getState()?.auths?.user?.id;

  const response = await API.get(path.notification(id));
  return response?.data?.data;
};
export const getAllCaseApi = async (parameter: any) => {
  const response: GetCaseFolderResponseTypes = await API.get(
    path.getAllcases(
      parameter.page,
      parameter.status,
      parameter.condition,
      parameter.my_judicial_division,
    ),
  );
  return response?.data?.data;
};
export const GetAllJudges = async () => {
  const response = await API.get(path.allJudges());
  return response?.data?.data;
};
export const GetAllDpps = async (parameter: any) => {
  const response = await API.get(path.allDpps(parameter));
  return response?.data?.data;
};
export const GetAllUsers = async () => {
  const response = await API.get(path.allUsers());
  return response?.data?.data;
};
export const addUserApi = async (data: any) => {
  const response = await API.post(path.addUsers(), data);
  return response?.data;
};
export const UpdateStaffRole = async (
  id: string,
  role: { parent_role: string },
) => {
  const response = await API.post(path.updateStaffRole(id), role);
  return response?.data;
};

export const deleteStaffApi = async (id: string) => {
  const response = await API.delete(path.deleteCourtStaff(id));
  return response?.data;
};
export const addCourtStaffApi = async (data: any) => {
  const response = await API.post(path.addCourtStaff(), data);
  return response?.data;
};

export const GetAllCourtStaffs = async () => {
  const response = await API.get(path.getCourtStaffs());
  return response?.data?.data;
};
export const DeletePostApi = async (
  id: any,
  dispatch: any,
  refetch: any,
  setSelectedData: any,
) => {
  dispatch(setGlobalLoading(true));

  try {
    const response = await API.delete(path.deleteUsers(id));
    if (response) {
      dispatch(setGlobalLoading(false));
      toast.success(response.data.message, {
        duration: 10_000,
      });
      setSelectedData(undefined);
      refetch();
    }
  } catch (error: any) {
    dispatch(setGlobalLoading(false));
    toast.error(error.response.data.message, {
      duration: 10_000,
    });
  }
};
export const EditUserApi = async (id: any, data: any) => {
  const response = await API.post(path.updateUsers(id), data);

  return response?.data;
};
export const GetCaseHolders = async (id: string) => {
  const response: CaseHoldersResponse = await API.get(path.getCaseHolders(id));
  return response?.data?.data;
};
export const GetCaseJoinRequest = async (id: string, dispatch: any) => {
  const response: CaseDetailsResponse = await API.get(
    path.showJoinCaseRequest(id),
  );

  const caseData = response?.data.data;
  dispatch(updateCaseDetails(caseData));
  dispatch(updateCase(caseData));
  return caseData;
};
export const ShowCaseFolder = async (id: string, dispatch: any) => {
  const response: CaseDetailsResponse = await API.get(path.showCaseFolder(id));
  const caseData = response?.data.data;
  dispatch(updateCaseDetails(caseData));
  dispatch(updateCase(caseData));
  return caseData;
};
export const CaseFolderPaymentApi = async (parameter: any) => {
  const response = await API.get(
    `payments/case-folder/${parameter.id}?page=${parameter?.page}&status=${parameter?.status ?? ""}`,
  );
  return response?.data?.data;
};

export const addSignatureApi = async (payload: any) => {
  const response = await API.post(path.AddSignature, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response?.data;
};

export const deleteSignatureApi = async (
  id: any,
  dispatch: any,
  refetch: any,
) => {
  dispatch(setGlobalLoading(true));

  try {
    const response = await API.delete(path.deleteSignature(id));
    if (response) {
      dispatch(setGlobalLoading(false));
      toast.success(response.data.message, {
        duration: 10_000,
      });

      refetch();
    }
  } catch (error: any) {
    dispatch(setGlobalLoading(false));
    toast.error(error.response.data.message, {
      duration: 10_000,
    });
  }
};

export const resolveQueryApi = async (id: string, payload: any) => {
  const response = await API.post(path.resolveQuery(id), payload);
  return response?.data;
};

export const getPaymentsApi = async (parameter: any) => {
  const response = await API.get(path.getPayments(parameter));
  return response?.data?.data;
};
export const PostQuery = async (data: any) => {
  const response = await API.post(path.caseQueries, data);
  return response?.data;
};

export const createCaseDocumentApi = async (payload: any) => {
  const response: CreateDocumentTypes = await API.post(
    path.createDocuements,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response?.data;
};
export const LoadJudicialDivisionApi = async () => {
  const response: JudicialDivisionTypes = await API.get(path.judicialDivisions);
  return response?.data;
};
export const LoadSubjectlMatterApi = async () => {
  const response: SubjectMatterTypes = await API.get(path.subjectMatters);
  return response?.data;
};

export const SearchCaseBySuitNoApi = async (parameter: any) => {
  const response = await API.get(path.searchCaseBySuitNo(parameter));
  return response?.data?.data;
};
