import MyCaseFolder from "../features/laisonofficer/casefolder";
import AddDocument from "../features/laisonofficer/casefolder/addDocument";
import Documents from "../features/laisonofficer/casefolder/documents";
import Notification from "../features/laisonofficer/notification";
import Overview from "../features/laisonofficer/overview";
import Settings from "../features/laisonofficer/settings";

export const LaisonOfficerRoute = [
  {
    id: 1,
    title: "Overview",
    component: Overview,
    path: "laison-officer/overview",
  },
  {
    id: 2,
    title: "Overview",
    component: MyCaseFolder,
    path: "/laison-officer/case-folders",
  },
  {
    id: 2,
    title: "Documents",
    component: Documents,
    path: "/laison-officer/case-folders/documents",
  },
  {
    id: "10",
    title: "documents",
    component: AddDocument,
    path: "/laison-officer/case-folders/documents/upload-document",
  },
  {
    id: 3,
    title: "Notifications",
    component: Notification,
    path: "/laison-officer/notifications",
  },
  {
    id: 4,
    title: "Settings",
    component: Settings,
    path: "/laison-officer/settings",
  },
];
