/* eslint-disable func-names */
/* eslint-disable react/jsx-no-bind */
import React from "react";
import { useNavigate } from "react-router-dom";

import AuthLayout, { AuthActions, AuthInput } from "../../layouts/auth";

function Verify() {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <div className='w-[458.35px] bg-white flex-col h-[445.89px] p-8 gap-y-7 rounded-lg flex items-center justify-start'>
        <span className='text-[24px] font-semibold text-black'>
          Verify your email address
        </span>
        <div className='w-full flex flex-col items-start gap-y-8'>
          <span className='text-sm font-normal text-black'>
            We’ve sent a mail to emjayanoruo@gmail.com with a link and a code.
            You can enter the code below or click the link in the email to
            verify your email address.
          </span>
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='Enter code'
            type='text'
            value=''
          />
        </div>

        <AuthActions
          actionText='Resend verification code.'
          btnText='Submit'
          handleAction={() => {}}
          handleClick={function (): void {
            throw new Error("Function not implemented.");
          }}
          isLoading={undefined}
        />
      </div>
    </AuthLayout>
  );
}

export default Verify;
