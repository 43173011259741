/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// import { Sidebar } from "../components/sidebar";
import Header from "../components/sidebar/Header";
import { useMediaQuery } from "../hooks";

interface LayoutProps {
  children: JSX.Element;
}

export const DashboardLayout = (props: LayoutProps) => {
  const location = useLocation();

  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");
  const [isSideNavVisible, setSideNavVisible] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isMobileView) {
      setSideNavVisible(false);
    } else {
      setSideNavVisible(true);
    }
  }, [isMobileView]);

  return (
    <div className='w-full '>
      <Header />

      <div className='bg-[#F6F8FF] h-[100vh] overflow-auto'>
        {props.children}
      </div>
    </div>
  );
};

export const OverLay = ({ children }: { children: React.ReactNode }) => (
  <div className='w-full h-full relative'>
    <div className='w-full h-[290px] absolute z-20 bg-[#08043F] shadow-header' />
    <div className='z-50 absolute w-full'>{children}</div>
  </div>
);
