/* eslint-disable func-names */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable unicorn/prevent-abbreviations */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import AuthLayout, { AuthActions, AuthInput } from "../../layouts/auth";

function SignUp() {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      setFile(file);
    }
  };
  return (
    <AuthLayout>
      <div className='w-[611px] bg-white flex-col h-[694px] py-8 px-5 gap-y-7 rounded-lg flex items-center justify-start'>
        <span className='text-[24px] font-semibold text-black'>Sign Up</span>
        <div className='w-full grid grid-cols-2 items-start gap-y-8 gap-x-5'>
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='Name of lawyer '
            type='text'
            value=''
          />
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='Legal mail'
            type='email'
            value=''
          />
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='Alternative email address'
            type='email'
            value=''
          />
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='Name of firm'
            type='text'
            value=''
          />
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='Phone number'
            type='text'
            value=''
          />
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='Whatsapp number'
            type='text'
            value=''
          />
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='SCN'
            type='text'
            value=''
          />
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional
            name=''
            placeholder='Address of service'
            type='email'
            value=''
          />
          <div className='w-full flex flex-col justify-end items-end      '>
            <div className='flex items-center justify-between px-3 py-3 w-full border border-dotted border-[#CACACA] rounded-lg'>
              {file ? (
                <span className='text-sm font-normal text-black'>
                  {file.name}
                </span>
              ) : (
                <span className='text-sm font-normal text-[#9A9A9A]'>
                  Call to bar certificate
                </span>
              )}

              <button
                className='text-[10px] font-normal w-[63px] p-2 border bg-[#CACACA] rounded-lg'
                // onClick={() => document.querySelector(".file_upload").click()}
                type='button'
              >
                Upload
              </button>
            </div>
            <span className='italic text-[#9A9A9A] text text-xs font-medium'>
              Coloured scan
            </span>
            <input
              accept='.pdf'
              className='sr-only file_upload'
              onChange={handleFileChange}
              type='file'
            />
          </div>
          <AuthInput
            handleChange={function (): void {
              throw new Error("Function not implemented.");
            }}
            isOptional={false}
            name=''
            placeholder='Password'
            type='password'
            value=''
          />
        </div>

        <AuthActions
          actionText='Already have an account? Log in.'
          btnText='Sign up'
          handleAction={() => navigate("/login")}
          handleClick={function (): void {
            throw new Error("Function not implemented.");
          }}
          isLoading={undefined}
        />
      </div>
    </AuthLayout>
  );
}

export default SignUp;
