/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Skeleton } from "@mui/material";
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { BsPlus, BsSearch } from "react-icons/bs";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { EMPTY, IS_SUCCESSFUL } from "../../../assets";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import Button from "../../../components/button";
import { CASE_FOLDER_COLUMN } from "../../../components/table/columns/LiaisonOfficer";
import { TableComponent } from "../../../components/table/Table";
import Typography from "../../../components/Typography";
import Modal from "../../../components/UI/modal";
import { useAppSelector } from "../../../hooks";
import { CaseFolder } from "../../../services/Laywer/types";
import { getAllCaseApi, GetAllDpps } from "../../../services/shared/service";
import { SendCaseApi } from "../../../services/unitHead/service";
import { globalLoading } from "../../../states/slices/authReducer";
import {
  getSelectedCase,
  getSelectedCaseDetails,
  setToggle,
  setToggle_3,
  toggleState,
  toggleState_3,
} from "../../../states/slices/partyInfo.slice";
import { UserType } from "../../../utils/Enums";

const Loader = lazy(() => import("../../../components/Loader/index"));
interface Dpp {
  id: string;
  name: string;
}

function MyCaseFolder() {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const Case: CaseFolder = useAppSelector(getSelectedCase);
  const CaseDetails = useAppSelector(getSelectedCaseDetails);
  const loading = useAppSelector(globalLoading);
  const [isDivision] = useState(0);
  const [type, setType] = useState("pending");
  const [filter, setFilter] = useState("");

  const [isSuccessful, setSuccessful] = useState(false);
  const [page, setPage] = useState(1);

  const params = useMemo(
    () => ({
      page,
      status: filter,
      my_judicial_division: isDivision,
    }),
    [page, filter, isDivision],
  );
  const { data: allCasesData, refetch } = useQuery(
    ["getAllCases", params],
    () => getAllCaseApi(params),
  );

  const toggle = useSelector(toggleState);
  const toggle3 = useSelector(toggleState_3);

  useEffect(() => {
    refetch();
  }, [loading, refetch]);

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  const [role, setRole] = useState<any>(UserType.LEGAL_OFFICER);
  const parameter = useMemo(() => ({ role }), [role]);

  const { data: DppUsers } = useQuery(["getAllDpps", parameter], () =>
    GetAllDpps(parameter),
  );

  const [selectedDppId, setSelectedDppId] = useState<string | null>(null);

  const handleDppSelection = (dppId: string) => {
    // Set selectedDppId to the clicked ID, making it a single-selection component
    setSelectedDppId((previousSelected) =>
      previousSelected === dppId ? null : dppId,
    );
  };

  const user_id = selectedDppId;

  return (
    <Suspense fallback={<Loader />}>
      <div className='px-[60px] py-[30px]'>
        <div className='w-full bg-white  pb-20 lg:p-8 shadow rounded-[5px]'>
          <div className='flex items-center justify-between w-full border-b border-[#EAECF0] pb-4'>
            <Typography textStyle='text-[22px] leading-[26px] font-bold'>
              Folders
            </Typography>
          </div>

          <div className='breadcrumb border-b border-[#EAECF0] py-4'>
            <Breadcrumb routes={[]} />
          </div>

          <div className='border-[#EAECF0] border-b pt-4'>
            <div className='flex items-center  space-x-6 text-[#98A2B3]'>
              <button
                className={`${type === "pending" ? "border-b-2 border-[#0035A3] text-[#0035A3]" : ""} text-[16px] leading-[19px]  py-4`}
                onClick={() => {
                  setType("pending");
                  setFilter("");
                }}
                type='button'
              >
                Pending
              </button>

              <button
                className={`${type === "history" ? "border-b-2 border-[#0035A3] text-[#0035A3]" : ""} text-[16px] leading-[19px]  py-4`}
                onClick={() => {
                  setType("history");
                }}
                type='button'
              >
                Folder History
              </button>
            </div>
          </div>

          <div className='w-full flex flex-row justify-end lg:justify-between flex-wrap items-start  pt-4 '>
            <div className=' lg:flex justify-between items-center w-full lg:w-1/3 mb-6 lg:mb-0'>
              <div className='flex items-center border border-[#D9D9D9] py-[8px] px-[10px] rounded-[15px]'>
                <input
                  className='bg-[transparent]  border-0 outline-0 w-[100%] placeholder:text-md placeholder:text-[#98A2B3] focus:outline-none focus:ring-0  '
                  placeholder='Search'
                  type='text'
                  value={search}
                  // onChange={searchNotificatio}
                />

                <BsSearch />
              </div>
            </div>
          </div>

          <div className='mt-8 '>
            <div className=' w-full lg:mb-0'>
              <Zoom>
                {allCasesData !== undefined && allCasesData?.length > 0 ? (
                  <div className='border-t-blue-gray-100  relative'>
                    <TableComponent
                      className='rounded-none rounded-t-0 w-[1400px] '
                      COLUMNS={CASE_FOLDER_COLUMN}
                      DATA={allCasesData}
                      page={page}
                      setPage={setPage}
                    />
                  </div>
                ) : allCasesData !== undefined && allCasesData?.length < 1 ? (
                  <div className='text-center text-xs w-full'>
                    <div className='flex flex-col justify-center items-center h-52'>
                      <img alt='' className='h-32' src={EMPTY} />
                      <p className='text-center text-sm mt-4'>
                        You have not created any case folder
                      </p>
                    </div>
                    <button
                      className='  border text-[#000] border-[#c5c5c5] py-[10px] px-[16px] rounded-[8px] text-[14px] leading-[20px] flex items-center mx-auto'
                      onClick={() => navigate("add-new")}
                      type='button'
                    >
                      <BsPlus className='mr-2 text-[15px]' />
                      <span>Create a new case</span>
                    </button>

                    {page > 1 && (
                      <div className='flex justify-end items-center w-full px-2 lg:px-4 py-2'>
                        <div className='flex justify-center items-center text-xs rounded-sm py-1  w-full '>
                          {page > 1 && (
                            <button
                              className=' cursor-pointer px-4 py-2 border-2 w-40 border-brand rounded-lg text-brand'
                              onClick={() => handlePrevious()}
                              type='button'
                            >
                              Previous
                            </button>
                          )}

                          <span className='mx-4 text-xs'>
                            Page <strong>{page}</strong>{" "}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='w-full mb-3 px-8 flex justify-between items-center'>
                    <Skeleton
                      className='w-[50px] rounded-full mb-4'
                      height={50}
                      variant='rectangular'
                    />
                    <div className='w-[80%]'>
                      <Skeleton
                        className='w-full mb-4'
                        height={20}
                        variant='rectangular'
                      />
                      <Skeleton
                        className='w-[80%] mb-4'
                        height={20}
                        variant='rectangular'
                      />
                    </div>
                  </div>
                )}
              </Zoom>
            </div>
          </div>
        </div>
        <Modal
          close={() => {
            dispatch(setToggle(false));
          }}
          maxWidth='xs'
          open={toggle}
        >
          <Zoom>
            <div className='px-12'>
              <div className='leading-[31px] text-[24px] font-[600] text-center'>
                Case folder details
              </div>
              <div className='mt-9 leading-[18px] text-[14px] font-[400]'>
                Case title
              </div>
              <div className='mt-4 font-medium leading-[21px] text-[16px] '>
                {CaseDetails?.title}
              </div>

              <hr className='my-6' />

              <div className='py-1 text-[16px] leading-[21px] font-[600]  capitalize'>
                INFORMATION OF PARTIES
              </div>

              {CaseDetails?.parties?.length > 0 &&
                CaseDetails?.parties?.map((item: any, index: number) => (
                  <>
                    <div className='my-5 font-semibold text-sm capitalize'>
                      {item.title}
                    </div>
                    <div
                      className='flex gap-5 justify-between mt-2 mb-5 text-sm'
                      key={index}
                    >
                      <div className='flex flex-col'>
                        <div>Full name</div>
                        <div className='mt-7'>Email address</div>
                        <div className='mt-7'>Phone number</div>
                        <div className='mt-7'>Whatsapp number</div>
                        <div className='mt-7' />
                      </div>
                      <div className='flex flex-col self-start font-medium'>
                        <div className='flex flex-col items-end pl-9'>
                          <div>{item.name}</div>
                          <div className='self-start mt-6'>{item.email}</div>
                          <div className='mt-7'>
                            {item.phone_number || "N/A"}
                          </div>
                          <div className='mt-7'>
                            {item.whatsapp_number || "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              <hr className='my-6' />

              <div className='py-1 text-[16px] leading-[21px] font-[600]  capitalize'>
                <div className='py-1 text-[16px] leading-[21px] font-[600]  capitalize'>
                  DETAILS OF COUNSEL
                </div>

                {CaseDetails?.counsel_details?.length > 0 &&
                  CaseDetails?.counsel_details?.map(
                    (item: any, index: number) => (
                      <>
                        <div className='my-5 font-semibold text-sm capitalize'>
                          {item.title}
                        </div>
                        <div
                          className='flex gap-5 justify-between mt-2 mb-5 text-sm'
                          key={index}
                        >
                          <div className='flex flex-col'>
                            <div>Full name</div>
                            <div className='mt-7'>Email address</div>
                            <div className='mt-7'>Phone number</div>
                            <div className='mt-7'>Whatsapp number</div>
                            <div className='mt-7' />
                          </div>
                          <div className='flex flex-col self-start font-medium'>
                            <div className='flex flex-col items-end pl-9'>
                              <div>{item.name}</div>
                              <div className='self-start mt-6'>
                                {item.email}
                              </div>
                              <div className='mt-7'>
                                {item.phone_number || "N/A"}
                              </div>
                              <div className='mt-7'>
                                {item.whatsapp_number || "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ),
                  )}
              </div>

              <Button
                bgColor='bg-brand_blue'
                className=' mb-2  mt-4 w-full py-4 rounded-2xl'
                onClick={() => dispatch(setToggle(false))}
                textColor='text-white'
                title='Close'
                type='button'
              />
            </div>
          </Zoom>
        </Modal>

        {/* modal for sending Folder */}
        <Modal
          close={() => {
            dispatch(setToggle_3(false));
          }}
          maxWidth='xs'
          open={toggle3}
        >
          <Zoom>
            <div className='px-12 pb-5'>
              <div className='self-center text-[21px] leading-[27px] mb-6  font-semibold text-center'>
                Send folder
              </div>

              <div className='flex flex-col   w-full mb-2 lg:mb-6'>
                {/* <label className='mb-3 flex justify-start items-center text-sm'>
                  Send folder
                </label> */}
                <select
                  className={` border-gray-200 appearance-none border text-sm rounded-md  w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                  name='type'
                  onChange={(event_) => setRole(event_.target.value)}
                  value={role}
                >
                  <option className='text-gray-200' value='' />
                  <option value={UserType.LEGAL_OFFICER}>Legal Officer</option>
                  <option value={UserType.DIRECTOR}>Director</option>

                  <option value={UserType.ATTORNEY_GENERAL}>
                    Attorney General
                  </option>
                  <option value={UserType.LIAISON_OFFICER}>
                    Liaison Officer
                  </option>
                </select>

                <div className='w-full  p-4 mb-6 '>
                  <h4 className='text-[#484848] pb-2 font-[500] leading-[21px] text-[16px]'>
                    Select officer
                  </h4>

                  {DppUsers?.map((item: { id: string; name: string }) => (
                    <div className='flex items-center my-1' key={item.id}>
                      <input
                        checked={selectedDppId === item.id}
                        className='rounded-[4px] border-[1.5px] text-[#383838] focus:outline-none  focus:ring-0'
                        onChange={() => handleDppSelection(item.id)}
                        type='checkbox'
                      />
                      <label className='ml-2'>{item.name}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div className='flex flex-col gap-3 justify-center w-full'>
                <Button
                  bgColor='bg-brand_blue'
                  className=' mb-2   w-full py-4 rounded-2xl'
                  onClick={() => {
                    SendCaseApi(
                      Case?.id,
                      dispatch,
                      user_id,
                      role,
                      setSuccessful,
                    );
                    dispatch(setToggle_3(false));
                  }}
                  textColor='text-white'
                  title='Send'
                  type='button'
                />
                <Button
                  bgColor='bg-[#E6E4FF]'
                  className=' mb-2  w-full py-4 rounded-2xl'
                  onClick={() => dispatch(setToggle_3(false))}
                  textColor='text-brand_blue'
                  title='Cancel'
                  type='button'
                />
              </div>
            </div>
          </Zoom>
        </Modal>

        <Modal
          close={() => setSuccessful(false)}
          maxWidth='xs'
          open={isSuccessful}
        >
          <Zoom>
            <div className='flex mx-auto'>
              <img alt='' src={IS_SUCCESSFUL} srcSet='' />
            </div>

            <div className='flex flex-col pt-3 md:pt-4 px-8 md:px-8 lg:py-4'>
              <div className='flex justify-center items-center flex-wrap mb-6'>
                <div className='flex flex-col pt-2  w-full'>
                  <p className='text-center text-[#474747] text-[20px] leading-[26px]'>
                    Case folder sent successful
                  </p>
                </div>
              </div>
              <div className='flex items-center w-full'>
                <Button
                  bgColor='bg-brand_blue'
                  className=' mb-2  mt-4 py-4 w-full rounded-[4px]'
                  isLoading={loading}
                  onClick={() => setSuccessful(false)}
                  textColor='text-white'
                  title='Close'
                  type='button'
                />
              </div>
            </div>
          </Zoom>
        </Modal>
        <Tooltip id='view-tooltip' />
      </div>
    </Suspense>
  );
}

export default MyCaseFolder;
